<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
import axios from "axios";
import Dashboard from '../views/SalesModule/Dashboard';
import CreateSalesOrder from '../views/SalesModule/SalesOrder/CreateSalesOrder';
import SalesOrder from '../views/SalesModule/SalesOrder/SalesOrder';
import DetailSalesOrder from '../views/SalesModule/SalesOrder/DetailSalesOrder';
import ReviseSalesOrder from '../views/SalesModule/SalesOrder/ReviseSalesOrder';
import SalesOrderApproval from '../views/SalesModule/SalesOrder/SalesOrderApproval';
import DetailSalesOrderApproval from '../views/SalesModule/SalesOrder/DetailSalesOrderApproval';
import DemoRequest from '../views/SalesModule/DemoRequest/DemoRequest';
import CreateDemoRequest from '../views/SalesModule/DemoRequest/CreateDemoRequest';
// import ProductDemo from '../views/SalesModule/ProductDemo/ProductDemo';
import Detail from '../views/SalesModule/SalesOrder/Detail';
import Quotation from '../views/SalesModule/Quotation/Quotation';
import CreateQuotation from '../views/SalesModule/Quotation/CreateQuotation';
import DetailQuotation from  '../views/SalesModule/Quotation/DetailQuotation';
import DetailDemoRequest from  '../views/SalesModule/DemoRequest/DetailDemoRequest';
import CreatePickSalesOrder from '../views/SalesModule/SalesOrder/CreatePickSalesOrder';
import SalesOrderPick from '../views/SalesModule/SalesOrder/SalesOrderPick';
import TodoSalesOrder from '../views/SalesModule/Quotation/TodoSalesOrder';
import UpdateQuotation from '../views/SalesModule/Quotation/UpdateQuotation';
import UpdateDemoRequest from '../views/SalesModule/DemoRequest/UpdateDemoRequest';
import Consignment from '../views/SalesModule/Consignment/Consignment';
import DetailConsignment from '../views/SalesModule/Consignment/DetailConsignment';
import CreateConsignment from '../views/SalesModule/Consignment/CreateConsignment';
import TodoSalesOrderConsignment from '../views/SalesModule/Consignment/TodoSalesOrderConsignment';
import TodoDeliveryOrderConsignment from '../views/SalesModule/Consignment/TodoDeliveryOrderConsignment';
import SalesOrderReport from '../views/SalesModule/SalesOrder/SalesOrderReport';
import PriceList from '../views/SalesModule/PriceList/PriceList';
import ProductReport from '../views/SalesModule/PriceList/ProductReport';



export default {
    name: 'Sales',
    components: {
        Dashboard,
        SalesOrder,
        CreateSalesOrder,
        DetailSalesOrder,
        ReviseSalesOrder,
        SalesOrderApproval,
        DetailSalesOrderApproval,
        DemoRequest,
        CreateDemoRequest,
        // ProductDemo,
        Detail,
        Quotation,
        CreateQuotation,
        DetailQuotation,
        DetailDemoRequest,
        CreatePickSalesOrder,
        SalesOrderPick,
        TodoSalesOrder,
        UpdateQuotation,
        UpdateDemoRequest,
        Consignment,
        DetailConsignment,
        CreateConsignment,
        TodoSalesOrderConsignment,
        TodoDeliveryOrderConsignment,
        SalesOrderReport,
        PriceList,
        ProductReport
    },
    async created() {
        try {
            await this.$store.dispatch('setLoading', true);
            const resp = await axios.get("user-service/auth/menu?module=sales", {
                headers: {
                    Authorization: localStorage.getItem("token") || "",
                },
            });
            if (resp.data.error === false) {
                this.listApplication = resp.data.data;
                await this.$store.dispatch('setMenu', resp.data.data);
                return;
            }
        } catch (error) {
            if (error.response != undefined) {
                if (error.response.data != undefined) {
                    if (error.response.data.error_auth != undefined) {
                        this.$swal.fire("Get Application List", error.response.data.message, "error");
                        this.$store.dispatch("logOut").then(() => {
                            this.$router.push("/login");
                        });
                        return;
                    }
                    return this.$swal.fire("Get Application List ", error.response.data.message, "error");
                }
            }

            this.$sentry.captureException(error);
            this.$swal.fire("Error", error.message, "error");
        } finally {
            this.$store.dispatch("setApp");
            this.$store.dispatch('setLoading', false);
        }
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'Dashboard';
        }
    }
}
</script>
