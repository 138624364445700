<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <router-link class="btn btn-outline-info" to="quotation" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Quotation List
        </router-link>
    </div>
    <div class="row" v-if="quotation.customer !== undefined">
        <div class="col-xl-9 col-xxl-10 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mx-0">
                                <div class="media align-items-center col-md-6 col-lg-4 col-xl-4 px-0  mb-md-0">
                                    <img class="mr-3 img-fluid rounded-circle" width="65" src="/assets/images/delivery.png" alt="DexignZone">
                                    <div class="media-body">
                                        <h4 class="my-0 text-black"> {{ quotation.customer.organization }}</h4>
                                        <small>{{ quotation.customer.phone_number }}</small>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4 col-xl-4 mb-md-0">
                                    <small class="text-title-css">No Quotation</small>
                                    <p class="text-detail-css">{{ quotation.no_quotation }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Product List</h4>
                            <!-- <b-button variant="outline-danger" size="sm" @click="handleReject">
                                <b-icon icon="x-square" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Reject
                            </b-button> -->
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-responsive-m">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Part Number</th>
                                            <th class="text-left">Product Name</th>
                                            <th>Qty</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in shelter" :key="index">
                                            <td class="text-left">{{ value.part_number }}</td>
                                            <td class="text-left">{{ value.product_name }}</td>
                                            <td>{{ value.qty }}</td>
                                            <td>{{ value.price.toLocaleString('id-ID') }}</td>
                                            <td>{{ value.discount.toLocaleString('id-ID') }}</td>
                                            <td>{{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" class="text-right">Sub Total</td>
                                            <td>{{ quotation.detail.sub_total.toLocaleString('id-ID') }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" class="text-right">Discount</td>
                                            <td>{{ quotation.detail.discount !== null ? quotation.detail.discount.toLocaleString('id-ID') : 0 }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" class="text-right">Tax</td>
                                            <!-- <td>{{ quotation.detail.include_tax == 1 ? 0 : ((quotation.detail.sub_total - quotation.detail.discount) * 0.1).toLocaleString('id-ID') }}</td> -->
                                            <td>{{ quotation.detail.include_tax == 1 ? 0 : (((quotation.detail.sub_total - quotation.detail.discount)/1.1) * 0.1).toLocaleString('id-ID') }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5" class="text-right">Amount Due</td>
                                            <td>{{ quotation.detail.total.toLocaleString('id-ID') }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-xxl-2 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card h-auto">
                        <div class="card bg-secondary sticky mb-0">
                            <div class="card-header border-0 pb-0">
                                <h5 class="card-title text-white mt-2">Note Order</h5>
                            </div>
                            <div class="card-body pt-3">
                                <p class="fs-14 op7 text-white">{{ quotation.note }} </p>
                            </div>
                            <div class="card-footer border-0 py-4 bg-primary">
                                <div class="media align-items-center">
                                    <img class="mr-3 img-fluid rounded-circle" width="50" :src="quotation.create_by_data.profile" alt="DexignZone">
                                    <div class="media-body">
                                        <h5 class="my-0 text-white"> {{ quotation.create_by_data.first_name }} <br> {{ quotation.create_by_data.title }} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card">
                        <div class="card-header border-0 pb-0">
                            <h4 class="card-title">Info</h4>
                        </div>
                        <div class="card-body">
                            <div class="widget-timeline-icon">
                                <ul class="timeline">
                                    <li v-if="quotation.created_at !== undefined">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">Created Quotation</h4>
                                            <p class="fs-15 mb-0 ">{{ quotation.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-footer">
                            <b-button variant="outline-primary" class="btn-block" @click="generatePDF(1)">
                                <i class="fa fa-print"></i>
                                Print as drg. Anita Agustin
                            </b-button>
                            <b-button variant="outline-warning" class="btn-block" @click="generatePDF(2)">
                                <i class="fa fa-print"></i>
                                Print as drg. Ganesha
                            </b-button>
                            <b-button variant="outline-info" class="btn-block" :to="'todo-sales-order?id=' + $route.query.id">
                                <i class="fa fa-save"></i>
                                Create to Sales Order
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PrintQuotation :data-quotation="quotation" signature-as="drg. Anita Agustin" signature-title="Dental Business Director" ref="pdfComponent1" hidden />
    <PrintQuotation :data-quotation="quotation" signature-as="drg. Ganesha" signature-title="Dental Business Manager" ref="pdfComponent2" hidden />
</div>
</template>

<script>
import axios from "axios";
import PrintQuotation from "./PrintQuotation";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "DetailQuotation",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Quotation | %s',
    },
    components: {
        PrintQuotation
    },
    data() {
        return {
            totalApproved: 0,
            products: [],
            quotation: {},
            shelter: [],
        };
    },
    async created() {
        this.onLoad();
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('sales-order');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/quotation/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    let totalApproved = 0;
                    this.products = resp.data.data.get_products;

                    // each every products
                    await resp.data.data.get_products.forEach(function (value, index) {

                        totalApproved += value.approved == 1 ? 1 : 0

                        if (index == 0) {
                            arr.push({
                                part_number: value.product.part_number,
                                product_name: value.product.name,
                                price: value.price,
                                discount: value.discount == null ? 0 : value.discount,
                                approved: value.approved,
                                qty: 1,
                                qty_approve: 1,
                                approve: value.approved == 1 ? 1 : 0
                            });
                        } else {

                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i].part_number == value.product.part_number) {
                                    found = true;
                                    arr[i].qty += 1;
                                    arr[i].qty_approve += 1;
                                    arr[i].discount += value.discount == null ? 0 : value.discount;
                                    arr[i].approve += value.approved == 1 ? 1 : 0

                                    break;
                                }
                            }

                            if (found === false) {
                                arr.push({
                                    part_number: value.product.part_number,
                                    product_name: value.product.name,
                                    price: value.price,
                                    discount: value.discount == null ? 0 : value.discount,
                                    approved: value.approved,
                                    qty: 1,
                                    qty_approve: 1,
                                    approve: value.approved == 1 ? 1 : 0
                                });
                            }
                        }
                    });
                    this.shelter = arr;
                    this.totalApproved = totalApproved;

                    return this.quotation = resp.data.data;
                }

                return this.$router.push('quotation');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Quotation", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Quotation ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async generatePDF(index) {
            if (index == 1) {
                this.$refs.pdfComponent1.generateReport();
            } else {
                this.$refs.pdfComponent2.generateReport();
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.text-detail-css {
    margin: 0;
    color: #484848;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
}

.text-title-css {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
}
</style>
