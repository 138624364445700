<template>
<div class="container-fluid" v-if="sales_order.detail !== undefined">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <!-- <b-button variant="outline-info" v-b-modal.modal-add>
                <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Register Product
            </b-button> -->
        <router-link class="btn btn-outline-info mr-2" to="sales-order-approval" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Sales Order List
        </router-link>
        <div v-if="sales_order.approved != 2 && sales_order.approved != 1">
            <b-button variant="outline-success mr-2" @click="handleApproveAll">
                <b-icon icon="check2-all" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Approve SO
            </b-button>
            <b-button variant="outline-danger" @click="handleReject">
                <b-icon icon="x-square" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Reject SO
            </b-button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Detail Sales Order</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <span class="btn btn-sm mr-2" :class="sales_order.last_status == 'No Process' ? 'btn-danger' : 'btn-success' ">{{ sales_order.last_status }}</span>

                        <span class="btn btn-sm btn-info" v-if="sales_order.approved == 0"> Pending </span>
                        <span class="btn btn-sm btn-success" v-else-if="sales_order.approved == 1"> Approved </span>
                        <span class="btn btn-sm btn-danger" v-else> Rejected </span>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Customer</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.customer.organization" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No. Sales Order</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.no_so" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No. Purchase Order</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.no_po" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Order Type</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.order_type" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-1">
                                <label class="text text-info">Payment Type</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.detail.payment_type" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-1">
                                <label class="text text-info">Currency</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.detail.currency" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-1">
                                <label class="text text-info">Date SO</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.date_so" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-1">
                                <label class="text text-info">Due Date</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.due_date" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-1" v-if="sales_order.detail.currency !== 'IDR'">
                                <label class="text text-info">Rate</label>
                                <input type="text" class="form-control" readonly required v-model="sales_order.detail.rate" />
                            </div>

                        </div>
                        <hr class="pb-2 mt-4">
                        <div class="form-row">
                            <div class="pt-4 table-responsive">
                                <table class="table table-bordered text-center">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Part Number</th>
                                            <th>Product Name</th>
                                            <th>Qty Request</th>
                                            <th>Qty Approved</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Total</th>
                                            <th width="15%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in shelter" :key="index">
                                            <td>{{ value.part_number }}</td>
                                            <td>{{ value.product_name }} <span v-if="value.is_bonus == 1" class="badge badge-xs badge-info">Bonus</span> </td>
                                            <td>{{ value.qty }}</td>
                                            <td>{{ value.approve }}</td>
                                            <td>{{ value.price.toLocaleString('id-ID') }}</td>
                                            <td>{{ value.discount.toLocaleString('id-ID') }}</td>
                                            <td>{{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') }}</td>
                                            <td>
                                                <div class="input-group input-success-o" v-if="value.approved == 0">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Qty</span>
                                                    </div>
                                                    <input type="number" class="form-control" v-model="value.qty_approve" min="1" :max="value.qty">
                                                    <div class="input-group-append">
                                                        <!-- <span class="input-group-text">@example.com</span> -->
                                                        <b-button @click="handleApprove(value)" title="Approve" variant="outline-success" size="sm"><i class="fa fa-check"></i> Approve </b-button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="text-right">Sub Total</td>
                                            <td>{{ sales_order.detail.sub_total.toLocaleString('id-ID') }}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="text-right">Shipping Charge</td>
                                            <td>{{ sales_order.detail.shipping_charge !== null ? sales_order.detail.shipping_charge.toLocaleString('id-ID') : 0 }}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="text-right">Discount</td>
                                            <td>{{ sales_order.detail.discount !== null ? sales_order.detail.discount.toLocaleString('id-ID') : 0 }}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="text-right">Tax</td>
                                            <td>{{ sales_order.detail.include_tax == 1 ? 0 : sales_order.detail.tax.toLocaleString('id-ID') }}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="6" class="text-right">Amount Due</td>
                                            <td>{{ sales_order.detail.total.toLocaleString('id-ID') }}</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer d-sm-flex justify-content-between align-items-center">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <!-- <p class="card-text text-dark d-inline">Total Item: {{ products.length || 0 }} <br> Total Selected Item: {{ selected.length || 0 }} </p> -->
                    </div>
                    <div class="mt-4">
                        <!-- <b-button title="Create Purchase Request" class="mr-1" :to="'todo-purchase-request?id='+ $route.query.id" variant="outline-info"><i class="fa fa-plus mr-1"></i> Create Purchase Request </b-button>
                        <b-button title="Create Delivery Order" class="mr-1" :to="'todo-delivery-order?id='+ $route.query.id" variant="outline-primary"><i class="fa fa-plus mr-1"></i> Create Delivery Order </b-button>
                         -->
                    </div>
                    <!-- <div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "DetailSalesOrder",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Sales Order | %s',
    },
    data() {
        return {
            selected: [],
            totalApproved: 0,
            products: [],
            sales_order: {},
            formInput: {
                no_po: '',
                no_so: '',
                id_sales_order: '',
                customer_name: '',
                products: []
            },
            shelter: [],
        };
    },
    async created() {
        this.onLoad()
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    // return this.$router.push('sales-order');
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    let totalApproved = 0;
                    this.products = resp.data.data.get_product_sos;

                    // each every products
                    await resp.data.data.get_product_sos.concat(resp.data.data.bonus_product).forEach(function (value, index) {

                        totalApproved += value.approved == 1 ? 1 : 0

                        if (index == 0) {
                            arr.push({
                                part_number: value.part_number,
                                product_name: value.product_name,
                                price: value.price,
                                discount: value.discount,
                                approved: value.approved,
                                id_product_so: [value.id],
                                qty: 1,
                                qty_approve: 1,
                                approve: value.approved == 1 ? 1 : 0,
                                is_bonus: value.is_bonus
                            });
                        } else {

                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i].part_number == value.part_number && arr[i].is_bonus == value.is_bonus) {
                                    found = true;
                                    arr[i].qty += 1;
                                    arr[i].qty_approve += 1;
                                    arr[i].discount += value.discount;
                                    arr[i].id_product_so.push(value.id);
                                    arr[i].approve += value.approved == 1 ? 1 : 0

                                    break;
                                }
                            }

                            if (found === false) {
                                arr.push({
                                    part_number: value.part_number,
                                    product_name: value.product_name,
                                    price: value.price,
                                    discount: value.discount,
                                    approved: value.approved,
                                    id_product_so: [value.id],
                                    qty: 1,
                                    qty_approve: 1,
                                    approve: value.approved == 1 ? 1 : 0,
                                    is_bonus: value.is_bonus
                                });
                            }
                        }
                    });
                    this.shelter = arr;
                    this.totalApproved = totalApproved;

                    return this.sales_order = resp.data.data;
                }

                // return this.$router.push('SalesOrder');
                return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        handleApprove(row) {
            if (row.qty_approve > row.qty) {
                this.$swal.fire("Warning", "Qty can't more then qty request", "warning");
                return false;
            }
            let id_product_so = row.id_product_so.slice(0, row.qty_approve).map(val => {
                return {
                    id_product_so: val
                }
            });

            this.$swal.fire({
                title: 'Are you sure approve this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Approve it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/sales_order/sales/approval", {
                                id: this.$route.query.id,
                                products: id_product_so
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },
        handleApproveAll() {

            this.$swal.fire({
                title: 'Are you sure approve this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Approve it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/sales_order/sales/approval", {
                                id: this.$route.query.id,
                                products: []
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },

        handleReject() {

            this.$swal.fire({
                title: 'Are you sure reject this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Reject it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/sales_order/sales/denial", {
                                id: this.$route.query.id,
                                products: []
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },
        // onRowSelected(items) {
        //     this.selected = items
        // },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
