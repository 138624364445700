<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <!-- <b-button variant="outline-info" v-b-modal.modal-add>
                <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Register Product
            </b-button> -->
        <router-link class="btn btn-outline-info" to="consignment" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Consignment List
        </router-link>
    </div>
    <div class="row">
        <div class="col-xl-9 col-xxl-10 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mx-0">
                                <div class="media align-items-center col-md-4 col-lg-3 col-xl-2 px-0  mb-md-0">
                                    <img class="mr-3 img-fluid rounded-circle" width="65" src="/assets/images/delivery.png" alt="DexignZone">
                                    <div class="media-body">
                                        <h4 class="my-0 text-black"> {{ shelter.customer.organization }}</h4>
                                        <small>{{ shelter.customer.phone_number }}</small>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No Sales Order Consignment</small>
                                    <p class="text-detail-css">{{shelter.no_so_cons}}</p>
                                </div>
                                <!-- <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No Sales Order</small>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No Delivery Order</small>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No Purchase Request</small>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No Purchase Order</small>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Product List</h4>
                        </div>
                        <div class="card-body">
                            <div id="accordion-two" class="accordion accordion-primary">
                                <div class="accordion__item">
                                    <div class="accordion__header" data-toggle="collapse" data-target="#bordered_collapseOne"> <span class="accordion__header--text">Outgoing Items</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseOne" class="collapse accordion__body show" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Part Number</th>
                                                            <th class="text-left">Product Name</th>
                                                            <th class="text-left">Status</th>
                                                            <!-- <th class="text-left">Transit Location</th> -->
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(value, index) in shelter.monitor_products" :key="index">
                                                            <td class="text-left">{{ value.part_number }}</td>
                                                            <td class="text-left">{{ value.product_name }}</td>
                                                            <!-- <td class="text-left">{{ value.status }}</td> -->
                                                            <span class="btn btn-xs mt-2" :class="value.status == 'Sold' ? 'btn-success' : 'btn-danger' ">{{ value.status }}</span>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion__item">
                                    <div class="accordion__header collapsed" data-toggle="collapse" data-target="#bordered_collapseTwo"> <span class="accordion__header--text">Requested Products</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseTwo" class="collapse accordion__body" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Part Number</th>
                                                            <th class="text-left">Product Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(value, index) in shelter.requested_products" :key="index">
                                                            <td class="text-left">{{ value.part_number }}</td>
                                                            <td class="text-left">{{ value.product_name }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-xxl-2 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card h-auto">
                        <div class="card bg-secondary sticky mb-0">
                            <div class="card-header border-0 pb-0">
                                <h5 class="card-title text-white mt-2">Note Order</h5>
                            </div>
                            <div class="card-body pt-3">
                                <p class="fs-14 op7 text-white">{{ shelter.note }} </p>
                            </div>
                            <div class="card-footer border-0 py-4 bg-primary">
                                <div class="media align-items-center">
                                    <img class="mr-3 img-fluid rounded-circle" width="50" :src="shelter.create_by_data.profile" alt="DexignZone">
                                    <div class="media-body">
                                        <h5 class="my-0 text-white"> {{ shelter.create_by_data.first_name }} <br> {{ shelter.create_by_data.title }} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card">
                        <div class="card-header border-0 pb-0">
                            <h4 class="card-title">Info</h4>
                        </div>
                        <div class="card-body">
                            <div class="widget-timeline-icon">
                                <ul class="timeline">
                                    <li v-if="shelter.created_at !== undefined">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">Created Consignment</h4>
                                            <p class="fs-15 mb-0 ">{{ shelter.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-footer">
                            <b-button variant="outline-info" class="btn-block" :to="'todo-delivery-order-consignment?id=' + $route.query.id">
                                <i class="fa fa-save"></i>
                                Create Delivery Order
                            </b-button>
                            <b-button v-if="shelter.monitor_products.length !== 0" variant="outline-info" class="btn-block" :to="'todo-sales-order-consignment?id=' + $route.query.id">
                                <i class="fa fa-save"></i>
                                Create Sales Order
                            </b-button>
                            <b-button v-if="shelter.monitor_products.length !== 0" variant="outline-info" class="btn-block" @click="returnItemConsignment('totalItem')" v-b-modal.modal-return-item>
                                <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
                                Return Item
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <b-modal id="modal-list-products" size="xl" title="Select Item to Return" button-size="sm" @ok="handleOk">
            <div class="pt-4 table-responsive">
                <div class="basic-form">
                    <form class="form-inline">
                        <div class="form-group mb-3">
                            <b-form-checkbox id="transit" v-model.number="formInput.transit" name="checkbox-1" :value="1" :unchecked-value="0" > </b-form-checkbox>
                            <label for="transit">Transit</label>
                        </div>
                    </form>
                </div>
                <div class="basic-form">
                    <div class="form-row" v-if="formInput.transit === 1">
                        <div class="form-group col-md-6 col-lg-4">
                            <label>Transit Date</label>
                            <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.transit_date">
                                </b-form-datepicker>
                        </div>
                        <div class="form-group col-md-6 col-lg-4">
                            <label>Warehouse</label>
                            <input type="email" class="form-control" placeholder="Email">
                        </div>
                    </div>

                    <div class="form-row" v-else>
                        <div class="form-group col-md-6 col-lg-4">
                            <label>Return Date</label>
                            <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.return_date">
                                </b-form-datepicker>
                        </div>
                    </div>

                   
                </div>
                <table class="table dark-table-bordered text-left">
                    <thead class="thead-dark">
                        <tr>
                            <th>Part Number</th>
                            <th class="text-left">Name</th>
                            <th>Return</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in listProduct" :key="index">
                            <td>{{ val.part_number }}</td>
                            <td class="text-left">{{ val.product_name }}</td>
                            <td>
                                <b-button v-if="val.selected" title="Remove" @click="selectItem(val)" class="mr-1" variant="outline-danger" pill size="sm"><i class="fa fa-minus"></i></b-button>
                                <b-button v-else title="Select" @click="selectItem(val)" class="mr-1" variant="outline-success" pill size="sm"><i class="fa fa-check-circle"></i></b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal> -->
    </div>
    <todo-return :props-form-input="propsFormInput" />
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import TodoReturn from './TodoReturn.vue'
const moment = require('moment');

export default {
    name: "DetailConsignment",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Consignment | %s',
    },
    components: {
        TodoReturn
    },
    data() {
        return {
            selected: [],
            listProduct: [],
            totalApproved: 0,
            products: [],
            formInput: {
                return_date: moment().format('YYYY-MM-DD'),
                transit_date: moment().format('YYYY-MM-DD'),
                id_sales_order: '',
                customer_name: '',
                products: [],
                transit: 0
            },
            shelter: [],
            propsFormInput: {}
        };
    },
    async created() {
        this.onLoad()
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('detail-consignment');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/consignment/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.shelter = resp.data.data;
                    return this.shelter.monitor_products.map((v) => {
                        v.selected = false
                    })
                }

                return this.$router.push('Consignment');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Consignment", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Consignment ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        onRowSelected(items) {
            this.selected = items
        },
        async handleOk() {

            this.formInput.products = this.listProduct.filter(val => val.selected).map(v => {
                return {
                    id: v.id,
                    return_date: v.return_date,
                    condition: v.condition,
                    note: v.note
                }
            })

            if (this.formInput.products.length == 0) {
                return this.$swal.fire("Error", "Please Select Product", "error");
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/demo_request/wh/return_product',
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.getData();
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async returnItemConsignment(action) {

            if (action == 'totalItem') {
                this.titleModal = 'Total Item';
                this.actionModal = 'total_item';

                // this.listProduct = this.shelter.monitor_products.filter(v => v.status == 'Consigning');
                this.propsFormInput = {
                    products: this.shelter.monitor_products.filter(v => v.status == 'Consigning' | v.status == 'On transit').map(function(val) {
                        return {
                            id_product_consignment: val.id,
                            selected: false,
                            product_name: val.product_name,
                            part_number: val.part_number,
                        }
                    }),
                    id: this.shelter.id,
                    customer_name: this.shelter.customer.organization
                }
                return 
            }
        },
        async selectItem(item) {
            console.log(item)
            item.selected = !item.selected
            console.log(item)
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
