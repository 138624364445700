<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <b-button variant="outline-info" to="sales-order">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Sales Order List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Sales Order</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-9 col-md-12">
                            <form ref="formAdd" @submit.prevent="handleSubmit" class="mt-5">
                                <div class="form-row">
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">No. Sales Order</label>
                                        <input type="text" class="form-control" readonly placeholder="Enter the number sales order" required v-model="formInput.no_so" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.no_so') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">No. Purchase Order (Customer)</label>
                                        <input type="text" class="form-control" placeholder="Enter the number purchase request" required v-model="formInput.no_po" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.no_po') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Order Type</label>
                                        <input type="text" class="form-control" required v-model="formInput.order_type" readonly />
                                        <label class="text-danger error">{{ validation.firstError('formInput.order_type') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Customer</label>
                                        <input type="text" class="form-control" required v-model="formInput.customer_organization" readonly />
                                        <!-- <label class="text-danger error">{{ validation.firstError('formInput.order_type') }}</label> -->
                                    </div>

                                    <div class="form-group col-md-4 col-lg-3 col-xl-2" v-if="formInput.order_type !== 'bonus'">
                                        <label class="text text-info">Payment Type</label>
                                        <input type="text" class="form-control" required v-model="formInput.payment_type" readonly />
                                        <!-- <v-select :options="payment_types" label="name" v-model="formInput.payment_type" @input="handlePaymentType" /> -->
                                        <label class="text-danger error">{{ validation.firstError('formInput.payment_type') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Currency</label>
                                        <v-select :options="currency" label="name" v-model="formInput.currency" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.currency') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Date SO</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.date_so">
                                        </b-form-datepicker>
                                        <label class="text-danger error">{{ validation.firstError('formInput.date_so') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Due Date</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.due_date" :min="formInput.date_so">
                                        </b-form-datepicker>
                                        <label class="text-danger error">{{ validation.firstError('formInput.due_date') }}</label>
                                    </div>
                                    <div class="form-group col-md-2 col-lg-1" v-show="formInput.currency != 'IDR'">
                                        <label class="text text-info">Rate</label>
                                        <input type="number" min="0" class="form-control" placeholder="Enter the rate" required v-model="formInput.rate" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.rate') }}</label>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="text text-info">Note</label>
                                        <textarea class="form-control" v-model="formInput.note" style="margin-top: 0px; margin-bottom: 0px; height: 129px;"></textarea>
                                        <label class="text-danger error">{{ validation.firstError('formInput.rate') }}</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr class="pb-3">
                    <div class="pt-4 table-responsive" v-if="listProduct.length > 0">
                        <table class="table dark-table-bordered text-center">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Part Number</th>
                                    <th>Name</th>
                                    <!-- <th>Qty</th> -->
                                    <th style="width: 6%">Qty</th>
                                    <th>Price</th>
                                    <th style="width: 10%">Disc (%)</th>
                                    <th>Total Disc</th>
                                    <th>Total</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, index) in listProduct" :key="index">
                                    <td>{{ val.part_number }}</td>
                                    <td>{{ val.name }}</td>
                                    <td>{{ val.qty }}</td>
                                    <!-- <td>
                                        <currency-input readonly type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.qty" @input="calculate(val)" @change="calculate(val)" />
                                    </td> -->
                                    <!-- <td>{{ val.price }}</td> -->
                                    <td>
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.price" @input="calculate(val)" />
                                    </td>
                                    <td>
                                        <div class="input-group input-info">
                                            <input type="number" min="0" class="form-control" @input="calculatePercent(val)" v-model.number="val.percent">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- <td>{{ val.percent }}%</td> -->
                                    <td>
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model.number="val.discount" @input="calculateDiscount(val)" />
                                    </td>
                                    <!-- <td>{{ val.discount }}</td> -->
                                    <td class="text-right">{{ ((val.qty * val.price) - val.discount).toLocaleString('id-ID') }}</td>
                                    <td>
                                        <div class="text-center">
                                            <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="deleteProduct(index)"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="tfoot-dark">
                                <tr>
                                    <td colspan="6" class="text-right"> Sub Total</td>
                                    <td class="text-right"> {{ calculateSubTotal.toLocaleString('id-ID') }} </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="text-right pt-4"> Shipping Charge</td>
                                    <td class="text-right">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.shipping_charge" @input="calculateShippingCharge" />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" class="text-right">&nbsp;</td>
                                    <td class="text-right">
                                        <div class="input-group input-info">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Discount</span>
                                            </div>
                                            <input type="number" min="0" class="form-control" v-model.number="formInput.percent" @input="calculateParentPercent" >
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.discount" />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="text-right">
                                        <div class="custom-control custom-checkbox">
                                            <input readonly type="checkbox" class="custom-control-input" v-model="formInput.include_tax" :true-value="1" :false-value="0" id="includeTax" @change="handleTax">
                                            <label class="custom-control-label" for="includeTax"> Include Tax </label>
                                        </div>
                                    </td>
                                    <td class="text-right"> {{ (formInput.tax_amount == 0) ? '' : formInput.tax_amount.toLocaleString('id-ID') }} </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="text-right">Amount Due</td>
                                    <td class="text-right"> {{ formInput.total_final.toLocaleString('id-ID') }} </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0" v-if="listProduct.length > 0">
                    <p class="card-text d-inline">
                        Total Amount Due : {{ calculateTotalAmount.toLocaleString('id-ID') }}
                    </p>
                    <button type="button" class="btn btn-outline-success float-right" @click="handleSubmit">
                        <i class="fa fa-save mr-1"></i> Create Sales Order
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';
import {
    Validator
} from 'simple-vue-validator';
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: 'TodoSalesOrderConsignment',
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Sales Order Based On Consignment | %s',
    },
    data() {
        return {
            consignment: [],
            customers: [],
            discount_types: [],
            order_types: [],
            payment_types: [],
            products: [],
            currency: ['IDR', 'USD', 'EUR', 'JPY', 'CNY', 'KWD', 'BHD', 'OMR', 'JOD', 'GBP', 'KYD', 'CHF', 'CAD', 'AUD', 'LYD', 'AZN'],
            listProduct: [],
            selected: [],
            product: {
                id_consignment: '',
                part_number: '',
                name: '',
                price: 0,
                discount: '',
                percent: 0,
                principle_name: '',
                qty: 1,
                id_product: ''
            },
            formInput: {
                id_consignment: '',
                id_customer: '',
                customer_organization: '',
                note: '',
                no_so: '',
                no_po: '',
                order_type: 'consignment',
                date_so: moment().format('YYYY-MM-DD'),
                due_date: '',
                id_sales: '',
                sub_total: 0,
                discount: '',
                percent: 0,
                include_tax: 1,
                tax_amount: 0,
                total: 0,
                total_final: 0,
                total_shelter: 0,
                payment_type: 'cash',
                currency: 'IDR',
                rate: 1,
                marketing_cost: '',
                shipping_charge: 0,
                products: []
            }
        }
    },
    validators: {
        // 'formInput.id_customer': function (value) {
        //     return Validator.value(value).required('Customer is required');
        // },
        'formInput.no_so': function (value) {
            return Validator.value(value).required('No. Sales Order is required');
        },
        'formInput.no_po': function (value) {
            return Validator.value(value).required('No Purchase Order is required');
        },
        'formInput.order_type': function (value) {
            return Validator.value(value).required('Order type is required');
        },
        'formInput.date_so': function (value) {
            return Validator.value(value).required('Date SO is required');
        },
        'formInput.due_date': function (value) {
            return Validator.value(value).required('Due Date is required');
        },
        'formInput.currency': function (value) {
            return Validator.value(value).required('Currency is required');
        },
    },
    async created() {
        await this.onLoad();
        this.requestNumberSO()
    },
    watch: {
        calculateSubTotal(subTotal) {
            this.formInput.sub_total = subTotal;
            this.calculateShippingCharge()
            // this.calculateParentPercent()
            this.handleTax()
        },
        calculateTotalAmount(total) {
            this.formInput.total_final = total;
        }
    },
    computed: {
        calculateSubTotal() {
            return this.listProduct.reduce((accumulator, current) => accumulator + ((current.price * current.qty) - current.discount), 0);
        },
        calculateTotalAmount() {
            // let tax = (this.formInput.include_tax == 0) ? this.formInput.total_shelter * 0.1 : 0;
            let tax = (this.formInput.include_tax == 0) ? (this.formInput.total_shelter/1.1) * 0.1 : 0;
            return ((this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount) + tax);
        },
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('consignment');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/consignment/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    this.formInput.id_customer = resp.data.data.id_customer;
                    this.formInput.id_consignment = resp.data.data.id;
                    this.formInput.customer_organization = resp.data.data.customer.organization;

                    // each every products
                    await resp.data.data.monitor_products.forEach(function (value, index) {
                        if (value.status == 'Consigning') {
                            if (index == 0) {
                                arr.push({
                                    id_consignment: value.id,
                                    part_number: value.part_number,
                                    name: value.product_name,
                                    is_bonus: 0,
                                    price: value.price,
                                    id_product: value.id_product,
                                    discount: value.discount == null ? 0 : value.discount,
                                    percent: 0,
                                    approved: value.approved,
                                    qty: 1
                                });
                            } else {

                                let found = false;

                                for (let i = 0; i < arr.length; i++) {
                                    if (arr[i].part_number == value.part_number) {
                                        found = true;
                                        arr[i].qty += 1;
                                        break;
                                    }
                                }

                                if (found === false) {
                                    arr.push({
                                        id_consignment: value.id,
                                        part_number: value.part_number,
                                        name: value.product_name,
                                        is_bonus: 0,
                                        price: value.price,
                                        id_product: value.id_product,
                                        discount: value.discount == null ? 0 : value.discount,
                                        percent: 0,
                                        approved: value.approved,
                                        qty: 1,
                                    });
                                }
                            }
                        }

                        // if (index == 0) {
                        //     arr.push({
                        //         id_consignment: value.id,
                        //         part_number: value.part_number,
                        //         name: value.product_name,
                        //         is_bonus: 0,
                        //         price: value.price,
                        //         id_product: value.id_product,
                        //         discount: value.discount == null ? 0 : value.discount,
                        //         percent: 0,
                        //         approved: value.approved,
                        //         qty: 1
                        //     });
                        // } else {

                        //     let found = false;

                        //     for (let i = 0; i < arr.length; i++) {
                        //         if (arr[i].part_number == value.part_number) {
                        //             found = true;
                        //             arr[i].qty += 1;
                        //             arr[i].discount += value.discount == null ? 0 : value.discount;

                        //             break;
                        //         }
                        //     }

                        //     if (found === false) {
                        //         arr.push({
                        //             id_consignment: value.id,
                        //             part_number: value.part_number,
                        //             name: value.product_name,
                        //             is_bonus: 0,
                        //             price: value.price,
                        //             id_product: value.id_product,
                        //             discount: value.discount == null ? 0 : value.discount,
                        //             percent: 0,
                        //             approved: value.approved,
                        //             qty: 1,
                        //         });
                        //     }
                        // }
                    });
                    this.listProduct = arr;

                    return this.consignment = resp.data.data;
                }

                return this.$router.push('consignment');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Consignment", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Consignment ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async handleBonus(input, row) {
            if (input == 1) {
                return row.price = 0
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/sales_order/sales/get_price', {
                        id_product: row.id_product
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    row.price = resp.data.data.price == null ? 0 : resp.data.data.price.selling_price
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Price", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Product Price", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
        async requestNumberSO() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'SO');
                params.append('back_date', 0);
                // params.append('date', new Date());
                params.append('date', moment().format('YYYY-MM-DD'));

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_so = resp.data.data.number;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Number SO", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Number SO", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async calculatePercent(row) {
            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        async calculateDiscount(row) {
            row.percent = (parseFloat(row.discount || 0) / (parseFloat(row.price || 0) * parseFloat(row.qty || 0))) * 100;
        },
        async calculate(row) {
            if (row.qty == "" || row.price == "") return;

            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        async calculateShippingCharge() {
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge;
            this.calculateParentPercent();
        },
        async calculateParentPercent() {
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge;
            this.formInput.discount = (parseFloat(this.formInput.percent || 0) / 100) * parseFloat(this.formInput.total_shelter || 0);
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount;
            this.handleTax()
        },
        async calculateParentDiscount() {
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount;
            this.formInput.percent = (parseFloat(this.formInput.discount || 0) / parseFloat(this.formInput.sub_total + this.formInput.shipping_charge || 0)) * 100;
            this.handleTax();
        },
        async handleTax() {
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount;
            this.formInput.tax_amount = (this.formInput.include_tax == 0) ? (this.formInput.total_shelter/1.1) * 0.1 : 0;
        },
        async deleteProduct(index) {
            this.listProduct.splice(index, 1);
        },
        async getOrderTypes() {
            try {
                const resp = await axios.get(
                    "dental-erp-service/sales_order/sales/order_type", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.order_types = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Order Types", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/customer", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.customers = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        // async onSearchProduct(query) {
        //     try {
        //         if (query.length == 0) {
        //             return false;
        //         }

        //         const resp = await axios.post(
        //             "dental-erp-service/product", {
        //                 limit: 20,
        //                 page: 1,
        //                 search: query,
        //                 column: "",
        //                 ascending: false,
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             this.products = resp.data.data;
        //         }
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Request Products", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Request Products ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     }
        // },
        // setSelectedProduct(product) {
        //     if (product == null) {
        //         this.product = {
        //             id_product: '',
        //             name: '',
        //             qty: this.product.qty
        //         }
        //         return;
        //     }

        //     this.product = {
        //         part_number: product.part_number,
        //         principle_name: product.principle_name,
        //         id_product: product.id,
        //         name: product.name,
        //         qty: this.product.qty,
        //         price: 0,
        //         discount: 0,
        //         percent: 0,
        //         is_bonus: 0,
        //     }
        // },
        // async handleOk() {
        //     if (this.product.id_product == "" || this.product.qty == "") {
        //         return false;
        //     }

        //     this.selected = [];

        //     try {
        //         await this.$store.dispatch("setLoading", true);
        //         let resp = await axios.post(
        //             'dental-erp-service/sales_order/sales/get_price', {
        //                 id_product: this.product.id_product
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             this.product.price = resp.data.data.price == null ? 0 : resp.data.data.price.selling_price
        //         }
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Error", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Request Product Price", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Request Product Price", error.message, "error");
        //     } finally {

        //         this.listProduct.push(this.product);
        //         this.product = {
        //             part_number: '',
        //             name: '',
        //             price: 0,
        //             discount: 0,
        //             percent: 0,
        //             principle_name: '',
        //             qty: 1,
        //             id_product: '',
        //             is_bonus: 0
        //         };
        //         this.$store.dispatch("setLoading", false);
        //     }
        // },
        async handleSubmit() {

            let validation = await this.$validate();
            if (!validation) {
                return false;
            }

            if (this.listProduct.length == 0) {
                this.$swal.fire("Error", 'The list product can not be empty', "error");
                return;
            }

            this.formInput.total = this.formInput.total_final

            this.formInput.products = [];
            await this.listProduct.forEach(element => {
                for (let index = 0; index < parseInt(element.qty); index++) {
                    this.formInput.products.push({
                        id_product_consignment: element.id_consignment,
                        id_product: element.id_product,
                        is_bonus: element.is_bonus,
                        price: element.price,
                        discount: element.discount / parseInt(element.qty),
                    })
                }
            });

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            'dental-erp-service/consignment/sales/todo_so',
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.$router.push('detail-consignment?id=' + this.$route.query.id)
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }

                }
            });

        },
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
