<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <b-button variant="outline-info" to="sales-order-pick">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Sales Order List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Sales Order</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0"></div> -->
                </div>
                <div class="card-body">
                    <div class="row">
                        <div :class="formInput.termins.length > 0 ? 'col-md-9' : 'col-md-12'">
                            <form ref="formAdd" @submit.prevent="handleSubmit" class="mt-5">
                                <div class="form-row">
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Customer</label>
                                        <v-select label="organization" :filterable="false" :options="customers" v-model="formInput.id_customer" :reduce="customer => customer.id" @search="onSearchCustomer">
                                            <template slot="no-options"> type to search customer... </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.organization }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.organization }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.id_customer') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">No. Sales Order</label>
                                        <input type="text" class="form-control" readonly placeholder="Enter the number sales order" required v-model="formInput.no_so" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.no_so') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">No. Purchase Order (Customer)</label>
                                        <input type="text" class="form-control" placeholder="Enter the number purchase request" required v-model="formInput.no_po" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.no_po') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Order Type</label>
                                        <v-select :options="order_types" @input="v => {
                                           if ( v == 'bonus') {
                                            formInput.number_termin = 0;
                                            formInput.payment_type = '';
                                            formInput.termins = [];
                                           }
                                        }" label="name" v-model="formInput.order_type" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.order_type') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2" v-if="formInput.order_type !== 'bonus'">
                                        <label class="text text-info">Payment Type</label>
                                        <v-select :options="payment_types" label="name" v-model="formInput.payment_type" @input="handlePaymentType" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.payment_type') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Currency</label>
                                        <v-select :options="currency" label="name" v-model="formInput.currency" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.currency') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Date SO</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.date_so">
                                        </b-form-datepicker>
                                        <label class="text-danger error">{{ validation.firstError('formInput.date_so') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Due Date</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.due_date" :min="formInput.date_so">
                                        </b-form-datepicker>
                                        <label class="text-danger error">{{ validation.firstError('formInput.due_date') }}</label>
                                    </div>
                                    <div class="form-group col-md-2 col-lg-2" v-show="formInput.payment_type == 'installment'">
                                        <label class="text text-info">Number Termins</label>
                                        <input type="number" min="0" class="form-control" placeholder="Enter the number termins" @change="handleTermin" v-model.number="formInput.number_termin" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.number_termin') }}</label>
                                    </div>
                                    <div class="form-group col-md-2 col-lg-1" v-show="formInput.currency != 'IDR'">
                                        <label class="text text-info">Rate</label>
                                        <input type="number" min="0" class="form-control" placeholder="Enter the rate" required v-model="formInput.rate" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.rate') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2" v-if="formInput.order_type !== 'bonus'">
                                        <label class="text text-info">Sales User</label>
                                        <v-select :options="sales_users" label="first_name" v-model="sales_user" @input="handleSalesUser">
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    <img :src='option.profile' style="width: 1.5rem;height: auto;" />
                                                    {{ option.first_name + ' ' + option.last_name }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    <img :src='option.profile' style="width: 1rem;height: auto;" />
                                                    {{ option.first_name + ' ' + option.last_name }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.id_sales') }}</label>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="text text-info">Note</label>
                                        <textarea class="form-control" v-model="formInput.note" style="margin-top: 0px; margin-bottom: 0px; height: 129px;"></textarea>
                                        <label class="text-danger error">{{ validation.firstError('formInput.rate') }}</label>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="note">Files</label>
                                        <b-form-file accept="image/jpeg,image/gif,image/png,application/pdf" multiple v-model="files"></b-form-file>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-3 col-sm-12" v-if="formInput.termins.length > 0">
                            <h3>Termins</h3>
                            <table class="table dark-table-bordered text-center">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Date</th>
                                        <th>Nominal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value, index) in formInput.termins" :key="index">
                                        <td>
                                            <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="value.date" :min="formInput.date_so">
                                            </b-form-datepicker>
                                        </td>
                                        <td>
                                            <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="value.nominal" />
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td> {{ totalTermin.toLocaleString('id-ID') || 0 }} </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <hr class="pb-3">
                    <form ref="formAdd" @submit.prevent="handleOk">
                        <div class="form-row">
                            <div class="form-group col-md-8 col-lg-9">
                                <label class="text text-info">Select Product</label>
                                <v-select label="name" :filterable="false" v-model="selected" :options="products" @search="onSearchProduct" @input="setSelectedProduct">
                                    <template slot="no-options"> type to search product... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <dl>
                                                <dt class="text text-sm text-muted">{{option.principle_name}}</dt>
                                                <dd>[{{option.part_number}}] {{option.name}}</dd>
                                            </dl>
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Qty</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" v-model.number="product.qty" placeholder="Enter the quantity" min="1">
                                    <div class="input-group-append">
                                        <b-button variant="outline-info" type="submit">
                                            <b-icon icon="plus-circle" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add Product
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="pt-4 table-responsive" v-if="listProduct.length > 0">
                        <table class="table dark-table-bordered text-center">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Bonus</th>
                                    <th>Part Number</th>
                                    <th>Name</th>
                                    <th style="width: 6%">Qty</th>
                                    <th>Price</th>
                                    <th style="width: 10%">Disc (%)</th>
                                    <th>Total Disc</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, index) in listProduct" :key="index">
                                    <td>
                                        <b-form-checkbox v-model.number="val.is_bonus" name="checkbox-1" value="1" unchecked-value="0" @change="v => handleBonus(v, val)"> </b-form-checkbox>
                                    </td>
                                    <td>{{ val.part_number }}</td>
                                    <td>{{ val.name }}</td>
                                    <td>
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.qty" @input="calculate(val)" @change="calculate(val)" />
                                    </td>
                                    <td>
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.price" readonly @input="calculate(val)" />
                                    </td>
                                    <td>
                                        <div class="input-group input-info">
                                            <input type="number" min="0" class="form-control" @input="calculatePercent(val)" v-model.number="val.percent">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model.number="val.discount" @input="calculateDiscount(val)" />
                                    </td>
                                    <td class="text-right">{{ ((val.qty * val.price) - val.discount).toLocaleString('id-ID') }}</td>
                                    <td>
                                        <div class="text-center">
                                            <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="deleteProduct(index)"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="tfoot-dark">
                                <tr>
                                    <td colspan="7" class="text-right"> Sub Total</td>
                                    <td class="text-right"> {{ calculateSubTotal.toLocaleString('id-ID') }} </td>
                                </tr>
                                <tr>
                                    <td colspan="7" class="text-right pt-4"> Shipping Charge</td>
                                    <td class="text-right">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.shipping_charge" @input="calculateShippingCharge" />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="text-right">&nbsp;</td>
                                    <td class="text-right">
                                        <div class="input-group input-info">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Discount</span>
                                            </div>
                                            <input type="number" min="0" class="form-control" v-model.number="formInput.percent" @input="calculateParentPercent">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.discount" @input="calculateParentDiscount" />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="7" class="text-right">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" v-model="formInput.include_tax" :true-value="1" :false-value="0" id="includeTax" @change="handleTax">
                                            <label class="custom-control-label" for="includeTax"> Include Tax </label>
                                        </div>
                                    </td>
                                    <td class="text-right"> {{ (formInput.tax_amount == 0) ? '' : formInput.tax_amount.toLocaleString('id-ID') }} </td>
                                </tr>
                                <tr>
                                    <td colspan="7" class="text-right">Amount Due</td>
                                    <td class="text-right"> {{ formInput.total_final.toLocaleString('id-ID') }} </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0" v-if="listProduct.length > 0">
                    <p class="card-text d-inline">
                        Total Amount Due : {{ calculateTotalAmount.toLocaleString('id-ID') }}
                    </p>
                    <button type="button" class="btn btn-outline-success float-right" @click="handleSubmit">
                        <i class="fa fa-save mr-1"></i> Create Sales Order
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';
import {
    Validator
} from 'simple-vue-validator';
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: 'CreatePickSalesOrder',
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Sales Order | %s',
    },
    data() {
        return {
            customers: [],
            sales_users: [],
            sales_user: '',
            discount_types: [],
            order_types: [],
            payment_types: [],
            products: [],
            file: [],
            files: [],
            currency: ['IDR', 'USD', 'EUR', 'JPY', 'CNY', 'KWD', 'BHD', 'OMR', 'JOD', 'GBP', 'KYD', 'CHF', 'CAD', 'AUD', 'LYD', 'AZN'],
            listProduct: [],
            selected: [],
            product: {
                part_number: '',
                name: '',
                price: 0,
                discount: 0,
                percent: 0,
                principle_name: '',
                qty: 1,
                id_product: ''
            },
            formInput: {
                id_customer: '',
                note: '',
                no_so: '',
                no_po: '',
                order_type: '',
                date_so: moment().format('YYYY-MM-DD'),
                due_date: '',
                id_sales: '',
                sub_total: 0,
                discount: 0,
                percent: 0,
                include_tax: 1,
                tax_amount: 0,
                total: 0,
                total_final: 0,
                total_shelter: 0,
                payment_type: '',
                currency: 'IDR',
                rate: 1,
                marketing_cost: '',
                shipping_charge: 0,
                number_termin: 0,
                termins: [],
                products: [],
            }
        }
    },
    validators: {
        'formInput.id_customer': function (value) {
            return Validator.value(value).required('Customer is required');
        },
        'formInput.no_so': function (value) {
            return Validator.value(value).required('No. Sales Order is required');
        },
        'formInput.no_po': function (value) {
            return Validator.value(value).required('No Purchase Order is required');
        },
        'formInput.order_type': function (value) {
            return Validator.value(value).required('Order type is required');
        },
        'formInput.date_so': function (value) {
            return Validator.value(value).required('Date SO is required');
        },
        'formInput.due_date': function (value) {
            return Validator.value(value).required('Due Date is required');
        },
        'formInput.currency': function (value) {
            return Validator.value(value).required('Currency is required');
        },
        'formInput.id_sales': function (value) {
            return Validator.value(value).required('User Sales is required');
        },
    },
    async created() {
        await this.getOrderTypes();
        await this.getPaymentTypes();
        this.requestNumberSO();
        this.getSalesUsers()
    },
    watch: {
        calculateSubTotal(subTotal) {
            console.log('calculate SubTotal', subTotal);
            this.formInput.sub_total = subTotal;
            this.calculateShippingCharge()
            this.handleTax()
        },
        calculateTotalAmount(total) {
            this.formInput.total_final = total;
            this.updateTermin()
        },
        files(newValue) {
            newValue.forEach(element => {
                var reader = new FileReader();
                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.file.push({
                        content: reader.result,
                        name: element.name,
                    })
                    // this.formInput.file.content = reader.result;
                    // this.formInput.file.name = element.name;
                };
            });
        }
    },
    computed: {
        calculateSubTotal() {
            return this.listProduct.reduce((accumulator, current) => accumulator + ((current.price * current.qty) - current.discount), 0);
        },
        calculateTotalAmount() {
            // let tax = (this.formInput.include_tax == 0) ? this.formInput.total_shelter * 0.1 : 0;
            let tax = (this.formInput.include_tax == 0) ? (this.formInput.total_shelter/1.1) * 0.1 : 0;
            return ((this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount) + tax);
        },
        totalTermin() {
            return this.formInput.termins.reduce((previous, current) => previous + current.nominal, 0);
        }
    },
    methods: {
        async handleSalesUser(value) {
            if (value == null) {
                this.formInput.id_sales = '';
                return false;
            }
            this.formInput.id_sales = value.id;
        },
        async getSalesUsers() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(
                    'user-service/auth/users-by-role?role=sales&is_access=1', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.sales_users = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Users", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Sales Users", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
        async handleBonus(input, row) {
            if (input == 1) {
                return row.price = 0
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/sales_order/sales/get_price', {
                        id_product: row.id_product
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    row.price = resp.data.data.price == null ? 0 : resp.data.data.price.selling_price
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Price", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Product Price", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
        async requestNumberSO() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'SO');
                params.append('back_date', 0);
                // params.append('date', new Date());
                params.append('date', moment().format('YYYY-MM-DD'));

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_so = resp.data.data.number;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Number SO", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Number SO", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        handlePaymentType() {
            // this.payment_type == 'installment'
            this.formInput.number_termin = 0;
            this.formInput.termins = [];
        },
        updateTermin() {
            console.log('Update termin');
            let nominal = 0;
            nominal = this.formInput.total_final / this.formInput.number_termin;

            this.formInput.termins.map((value) => {
                value.nominal = nominal
            })
        },
        handleTermin() {
            this.formInput.termins = [];

            let nominal = 0;
            nominal = this.formInput.total_final / this.formInput.number_termin;

            for (let index = 0; index < parseInt(this.formInput.number_termin); index++) {
                this.formInput.termins.push({
                    nominal: nominal,
                    date: ''
                })
            }
        },
        async calculatePercent(row) {
            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        async calculateDiscount(row) {
            row.percent = (parseFloat(row.discount || 0) / (parseFloat(row.price || 0) * parseFloat(row.qty || 0))) * 100;
        },
        async calculate(row) {
            if (row.qty == "" || row.price == "") return;

            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        async calculateShippingCharge() {
            console.log('calculate Shipping Charge');

            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge;
            this.calculateParentPercent();
        },
        async calculateParentPercent() {
            console.log('calculate Parent Percent');
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge;
            this.formInput.discount = (parseFloat(this.formInput.percent || 0) / 100) * parseFloat(this.formInput.total_shelter || 0);
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount;
            // this.calculateParentDiscount();
            this.handleTax()
        },
        async calculateParentDiscount() {
            console.log('calculate Parent Discount');
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount;
            this.formInput.percent = (parseFloat(this.formInput.discount || 0) / parseFloat(this.formInput.sub_total + this.formInput.shipping_charge || 0)) * 100;
            this.handleTax();
        },
        async handleTax() {
            console.log('handle Tax');
            this.formInput.total_shelter = this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount;
            this.formInput.tax_amount = (this.formInput.include_tax == 0) ? (this.formInput.total_shelter/1.1) * 0.1 : 0;
            // this.formInput.tax_amount = (this.formInput.include_tax == 0) ? this.formInput.total_shelter * 0.1 : 0;
        },
        async getOrderTypes() {
            try {
                const resp = await axios.get(
                    "dental-erp-service/sales_order/sales/order_type", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.order_types = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Order Types", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getPaymentTypes() {
            try {
                const resp = await axios.get(
                    "dental-erp-service/sales_order/sales/payment_type", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.payment_types = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Payment Types", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/customer", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.customers = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/product", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.products = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Products", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Products ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        setSelectedProduct(product) {
            if (product == null) {
                this.product = {
                    id_product: '',
                    name: '',
                    qty: this.product.qty
                }
                return;
            }

            this.product = {
                part_number: product.part_number,
                principle_name: product.principle_name,
                id_product: product.id,
                name: product.name,
                qty: this.product.qty,
                price: 0,
                discount: 0,
                percent: 0,
                is_bonus: 0,
            }
        },
        async deleteProduct(index) {
            this.listProduct.splice(index, 1);
        },
        async handleOk() {
            if (this.product.id_product == "" || this.product.qty == "") {
                return false;
            }

            this.selected = [];

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/sales_order/sales/get_price', {
                        id_product: this.product.id_product
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.product.price = resp.data.data.price == null ? 0 : resp.data.data.price.selling_price
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Price", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Product Price", error.message, "error");
            } finally {

                this.listProduct.push(this.product);
                this.product = {
                    part_number: '',
                    name: '',
                    price: 0,
                    discount: 0,
                    percent: 0,
                    principle_name: '',
                    qty: 1,
                    id_product: '',
                    is_bonus: 0
                };
                this.$store.dispatch("setLoading", false);
            }
        },

        async handleUpload(id_so) {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/sales_order/upload/po', {
                        id_ref: id_so,
                        file: this.file
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async handleSubmit() {

            let validation = await this.$validate();
            if (!validation) {
                return false;
            }

            let totalTerimin = this.formInput.termins.reduce((previous, current) => previous + current.nominal, 0);

            if (this.formInput.payment_type == 'installment') {
                if (totalTerimin != this.formInput.total_final) {
                    this.$swal.fire("Error", 'Total payment of installment must be equals to total transaction', "error");
                    return;
                }
            }

            if (this.listProduct.length == 0) {
                this.$swal.fire("Error", 'The list product can not be empty', "error");
                return;
            }

            this.formInput.total = this.formInput.total_final

            this.formInput.products = [];
            await this.listProduct.forEach(element => {
                // console.log(element);
                for (let index = 0; index < parseInt(element.qty); index++) {
                    this.formInput.products.push({
                        id_product: element.id_product,
                        is_bonus: element.is_bonus,
                        price: element.price,
                        discount: element.discount / parseInt(element.qty),
                    })
                }
            });

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            'dental-erp-service/sales_order/sales/store',
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            if (this.file.length > 0) {
                                return this.handleUpload(resp.data.id);
                            }

                            this.$swal.fire("Success", resp.data.message, "success");
                            // this.formInput = {
                            //     id_customer: '',
                            //     no_so: '',
                            //     no_po: '',
                            //     products: []
                            // }
                            // this.listProduct = [];
                            // this.$router.push('sales-order');
                            // this.$router.go(-1)
                            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                        }
                    } catch (error) {
                        // console.log(error);
                        // this.$swal.fire("Error", error.response.data.message, "error");
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }

                }
            });

        },
        clearForm() {
            var self = this; //you need this because *this* will refer to Object.keys below`
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.formInput).forEach(function (key) {
                self.formInput[key] = '';
            });
        }
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
