<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <b-button variant="outline-info" to="demo-request">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Demo Request List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Demo Request</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="" class="mt-5">
                        <div class="form-row">
                            <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                <label class="text text-info">Select Customer</label>
                                <v-select label="organization" v-model="formInput.customer" :filterable="false" :options="customers" @input="setSelectedCustomer" @search="onSearchCustomer">
                                    <template slot="no-options"> type to search customer... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.organization }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.organization }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                <label class="text text-info">Start Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.start_date">
                                </b-form-datepicker>
                                <label class="text-danger error">{{ validation.firstError('formInput.start_date') }}</label>
                            </div>
                            <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                <label class="text text-info">End Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.end_date" :min="formInput.start_date">
                                </b-form-datepicker>
                                <label class="text-danger error">{{ validation.firstError('formInput.end_date') }}</label>
                            </div>
                        </div>
                    </form>
                    <hr class="pb-2">
                    <div class="pt-4 table-responsive" v-if="listProduct.length > 0">
                        <table class="table text-center">
                            <thead>
                                <tr>
                                    <th>Part Number</th>
                                    <th>Name</th>
                                    <th>Qty</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, index) in listProduct" :key="index">
                                    <td>{{ val.part_number }}</td>
                                    <td>{{ val.name }}</td>
                                    <td>
                                        <!-- <input min="1" type="number" v-model.number="val.qty" class="form-control"> -->
                                        {{ val.qty }}
                                    </td>
                                    <!-- <td>
                                        <div class="text-center">
                                            <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="deleteProduct(index)"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0" v-if="listProduct.length > 0">
                    <button type="button" class="btn btn-outline-success mb-1 mr-1 float-right" @click="handleSubmit"><i class="fa fa-save  mr-1"></i> Update Demo Request</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import {
    Validator
} from 'simple-vue-validator';
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: 'UpdateDemoRequest',
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Update Demo Request | %s',
    },
    data() {
        return {
            customers: [],
            products: [],
            product: {
                part_number: '',
                name: '',
                qty: 1,
                principle_name: '',
                id_product: ''
            },
            demoRequest: {},
            selected: [],
            listProduct: [],
            formInput: {
                id: '',
                id_customer: '',
                customer: {},
                start_date: moment().format('YYYY-MM-DD'),
                end_date: '',
                products: []
            }
        }
    },
    validators: {
        'formInput.id_customer': function (value) {
            return Validator.value(value).required('Customer is required');
        },
        'formInput.start_date': function (value) {
            return Validator.value(value).required('No. Sales Order is required');
        },
        'formInput.end_date': function (value) {
            return Validator.value(value).required('End Date is required');
        },
        // 'formInput.products.id_product': function (value) {
        //     return Validator.value(value).required('Product is required');
        // },
    },
    async created() {
        this.onLoad();
        await this.onSearchCustomer('a');
        await this.onSearchProduct('a');
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('demo-request');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/demo_request/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    this.formInput.id_customer = resp.data.data.customer.id;
                    this.formInput.customer = resp.data.data.customer;
                    this.formInput.end_date = resp.data.data.end_date;
                    this.formInput.start_date = resp.data.data.start_date;
                    this.formInput.id = resp.data.data.id;

                    // this.products = resp.data.data.get_product_sos;

                    // each every products
                    await resp.data.data.get_products.forEach(function (value, index) {

                        if (index == 0) {
                            arr.push({
                                part_number: value.product.part_number,
                                name: value.product.name,
                                qty: 1
                            });
                        } else {

                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i].part_number == value.product.part_number) {
                                    found = true;
                                    arr[i].qty += 1;
                                    break;
                                }
                            }

                            if (found === false) {
                                arr.push({
                                    part_number: value.product.part_number,
                                    name: value.product.name,
                                    qty: 1
                                });
                            }
                        }
                    });
                    this.listProduct = arr;

                    return this.demoRequest = resp.data.data;
                }

                return this.$router.push('sales-order');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/customer", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.customers = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/product_list/wh", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.products = resp.data.data;
                }
            } catch (error) {

                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Products", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Products ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        setSelectedProduct(product) {
            if (product == null) {
                this.product = {
                    part_number: '',
                    id_product: '',
                    name: '',
                    principle_name: '',
                    qty: this.product.qty
                };
                return;
            }
            this.product = {
                part_number: product.part_number,
                id_product: product.id,
                name: product.name,
                principle_name: product.principle_name,
                qty: this.product.qty
            }
        },
        setSelectedCustomer(value) {
            if (value == null) {
                this.formInput.id_customer = ''
                this.formInput.customer = {};
                return;
            }
            this.formInput.id_customer = value.id
            this.formInput.customer = value;
        },
        // async deleteProduct(index) {
        //     this.listProduct.splice(index, 1);
        // },
        async handleOk() {
            if (this.product.id_product == "") {
                return false;
            }

            let index = this.listProduct.findIndex((obj => obj.id_product == this.product.id_product));
            this.selected = [];
            if (index >= 0) {
                this.listProduct[index].qty = parseInt(this.listProduct[index].qty) + parseInt(this.product.qty);
                return;
            }

            await this.listProduct.push(this.product);
            this.product = {
                part_number: '',
                name: '',
                qty: 1,
                principle_name: '',
                id_product: ''
            };
        },
        async handleSubmit() {

            this.$validate().then(async (success) => {
                if (!success) {
                    return false;
                }

                this.formInput.products = [];
                await this.listProduct.forEach(element => {
                    for (let index = 0; index < parseInt(element.qty); index++) {
                        this.formInput.products.push({
                            id_product: element.id_product,
                            qty: this.product.qty
                        })
                    }
                });

                try {
                    await this.$store.dispatch("setLoading", true);
                    let resp = await axios.post(
                        'dental-erp-service/demo_request/sales/update',
                        this.formInput, {
                            headers: {
                                Authorization: localStorage.getItem("token") || "",
                            },
                        }
                    );

                    if (resp.data.error === false) {
                        this.$swal.fire("Success", resp.data.message, "success");
                        this.formInput = {
                            products: []
                        };
                        this.listProduct = [];
                        this.$router.push('demo-request');
                    }
                } catch (error) {
                    if (error.response != undefined) {
                        if (error.response.data != undefined) {
                            if (error.response.data.error_auth != undefined) {
                                this.$swal.fire("Error", error.response.data.message, "error");
                                this.$store.dispatch("logOut").then(() => {
                                    this.$router.push("/login");
                                });
                                return;
                            }
                            return this.$swal.fire("Request ", error.response.data.message, "error");
                        }
                    }

                    this.$sentry.captureException(error);
                    this.$swal.fire("Error", error.message, "error");
                } finally {
                    await this.$store.dispatch("setLoading", false);
                }
            });
        },
        clearForm() {
            var self = this;
            Object.keys(this.formInput).forEach(function (key) {
                self.formInput[key] = '';
            });
        }
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
