<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <router-link class="btn btn-outline-info" to="demo-request" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Demo Request
        </router-link>
    </div>
    <!-- <div class="row" v-if="demo_request.customer !== undefined"> -->
    <div class="row">
        <div class="col-xl-9 col-xxl-10 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mx-0">
                                <div class="media align-items-center col-md-4 col-lg-3 col-xl-2 px-0  mb-md-0">
                                    <img class="mr-3 img-fluid rounded-circle" width="65" src="/assets/images/delivery.png" alt="DexignZone">
                                    <div class="media-body">
                                        <h4 class="my-0 text-black"> {{ demo_request.customer.organization }}</h4>
                                        <small>{{ demo_request.customer.phone_number }}</small>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No. Delivery Order</small>
                                    <p class="text-detail-css">{{demo_request.no_do}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Type</small>
                                    <p class="text-detail-css">{{demo_request.do.type}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Product Requested</h4>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-responsive-m">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Part Number</th>
                                            <th class="text-left">Product Name</th>
                                            <th>Condition</th>
                                            <th>Note</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <td></td>
                                        <tr v-for="(value, index) in demo_request.get_products" :key="index">
                                            <td class="text-left">
                                                {{ value.product.part_number }}
                                            </td>
                                            <td class="text-left">
                                                {{ value.product.name }}
                                            </td>
                                            <td>
                                                <star-rating class="ml-1" v-model="value.condition" v-bind:star-size="30" read-only :show-rating="false"></star-rating>
                                            </td>
                                            <td class="text-left">{{ value.note }}</td>
                                            <td class="text-left" v-if="value.return_by && value.update_by !== null">Returned</td>
                                            <td class="text-left" v-else-if="value.return_by == null && value.update_by !== null">Not Returned</td>
                                            <td class="text-left" v-else>Not sent</td>
                                            <!-- <span class="btn-info btn-sm" v-if="value.return_by && value.update_by !== null"> Test </span>
                                            <span class="btn-info btn-sm" v-else-if="value.return_by == null && value.update_by !== null"> Test </span> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-xxl-2 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card h-auto">
                        <div class="card bg-secondary sticky mb-0">
                            <div class="card-header border-0 pb-0">
                                <h5 class="card-title text-white mt-2">Note Order</h5>
                            </div>
                            <div class="card-body pt-3">
                                <p class="fs-14 op7 text-white">Demo Request Note </p>
                            </div>
                            <div class="card-footer border-0 py-4 bg-primary">
                                <div class="media align-items-center">
                                    <img class="mr-3 img-fluid rounded-circle" width="50" :src="demo_request.create_by_data.profile" alt="DexignZone">
                                    <div class="media-body">
                                        <h5 class="my-0 text-white"> {{ demo_request.create_by_data.first_name }} <br> {{ demo_request.create_by_data.title }} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import StarRating from 'vue-star-rating'

export default {
    name: "DetailDemoRequest",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Demo Request | %s',
    },
    components: {
        StarRating
    },
    data() {
        return {
            demo_request: {},
        };
    },
    async created() {
        this.onLoad();
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('demo-request');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/demo_request/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.demo_request = resp.data.data;
                }

                return this.$router.push('demo-request');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Demo Request", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Demo Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        // async getTimeline() {
        //     try {
        //         if (!this.$route.query.id) {
        //             return this.$router.push('sales-order');
        //         }

        //         const resp = await axios.post(
        //             "dental-erp-service/sales_order/sales/timeline", {
        //                 id: this.$route.query.id,
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.timeline = resp.data.data;
        //         }

        //         return this.$router.push('sales-order');
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Request Sales Order", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     }
        // },
        // async getProductSO() {
        //     try {
        //         if (!this.$route.query.id) {
        //             return this.$router.push('sales-order');
        //         }

        //         const resp = await axios.post(
        //             "dental-erp-service/sales_order/sales/find_pr", {
        //                 id: this.$route.query.id,
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.product_pr = resp.data.data.get_products || [];
        //         }

        //         return this.$router.push('sales-order');
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Error", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Error ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     }
        // },
        // async getProductPO() {
        //     try {
        //         if (!this.$route.query.id) {
        //             return this.$router.push('sales-order');
        //         }

        //         const resp = await axios.post(
        //             "dental-erp-service/sales_order/sales/find_po", {
        //                 id: this.$route.query.id,
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.product_po = resp.data.data || [];
        //         }

        //         return this.$router.push('sales-order');
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Error", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Error ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     }
        // },
        // async getProductRI() {
        //     try {
        //         if (!this.$route.query.id) {
        //             return this.$router.push('sales-order');
        //         }

        //         const resp = await axios.post(
        //             "dental-erp-service/sales_order/sales/find_receive", {
        //                 id: this.$route.query.id,
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.product_ri = resp.data.data.items_receive || [];
        //         }

        //         return this.$router.push('sales-order');
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Error", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Error ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     }
        // },
        // async getProductDO() {
        //     try {
        //         if (!this.$route.query.id) {
        //             return this.$router.push('sales-order');
        //         }

        //         const resp = await axios.post(
        //             "dental-erp-service/sales_order/sales/find_do", {
        //                 id: this.$route.query.id,
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.product_do = resp.data.data || [];
        //         }

        //         return this.$router.push('sales-order');
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Error", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Error ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     }
        // },
        onRowSelected(items) {
            this.selected = items
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.text-detail-css {
    margin: 0;
    color: #484848;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
}

.text-title-css {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
}
</style>
