<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <b-button variant="outline-info" to="sales-order-pick">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Consignment List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Consignment</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0"></div> -->
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-9 col-md-12">
                            <form ref="formAdd" @submit.prevent="handleSubmit" class="mt-5">
                                <div class="form-row">
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Customer</label>
                                        <v-select label="organization" :filterable="false" :options="customers" v-model="formInput.id_customer" :reduce="customer => customer.id" @search="onSearchCustomer">
                                            <template slot="no-options"> type to search customer... </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.organization }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.organization }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.id_customer') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">No. Sales Order Consignment</label>
                                        <input type="text" class="form-control" placeholder="Enter the number sales order" disabled required v-model="formInput.no_so_cons" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.no_so') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2" v-if="formInput.order_type !== 'bonus'">
                                        <label class="text text-info">Sales User</label>
                                        <v-select :options="sales_users" label="first_name" v-model="sales_user" @input="handleSalesUser">
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    <img :src='option.profile' style="width: 1.5rem;height: auto;" />
                                                    {{ option.first_name + ' ' + option.last_name }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    <img :src='option.profile' style="width: 1rem;height: auto;" />
                                                    {{ option.first_name + ' ' + option.last_name }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.id_sales') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Start Date</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.start_date">
                                        </b-form-datepicker>
                                        <label class="text-danger error">{{ validation.firstError('formInput.start_date') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">End Date</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.end_date" :min="formInput.start_date">
                                        </b-form-datepicker>
                                        <label class="text-danger error">{{ validation.firstError('formInput.end_date') }}</label>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="text text-info">Note</label>
                                        <textarea class="form-control" v-model="formInput.note" style="margin-top: 0px; margin-bottom: 0px; height: 129px;"></textarea>
                                        <label class="text-danger error">{{ validation.firstError('formInput.rate') }}</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <hr class="pb-3">
                    <form ref="formAdd" @submit.prevent="handleOk">
                        <div class="form-row">
                            <div class="form-group col-md-8 col-lg-9">
                                <label class="text text-info">Select Product</label>
                                <v-select label="name" :filterable="false" v-model="selected" :options="products" @search="onSearchProduct" @input="setSelectedProduct">
                                    <template slot="no-options"> type to search product... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <dl>
                                                <dt class="text text-sm text-muted">{{option.principle_name}}</dt>
                                                <dd>[{{option.part_number}}] {{option.name}}</dd>
                                            </dl>
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Qty</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" v-model.number="product.qty" placeholder="Enter the quantity" min="1">
                                    <div class="input-group-append">
                                        <b-button variant="outline-info" type="submit">
                                            <b-icon icon="plus-circle" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add Product
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="pt-4 table-responsive" v-if="listProduct.length > 0">
                        <table class="table dark-table-bordered text-center">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Part Number</th>
                                    <th>Name</th>
                                    <th style="width: 6%">Qty</th>
                                    <th>Price</th>
                                    <th style="width: 10%">Disc (%)</th>
                                    <th>Total Disc</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, index) in listProduct" :key="index">
                                    <td style="white-space: nowrap;">{{ val.part_number }}</td>
                                    <td style="white-space: nowrap;">{{ val.name }}</td>
                                    <td style="min-width: 100px;">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.qty" @input="calculate(val)" @change="calculate(val)" />
                                    </td>
                                    <td style="min-width: 200px;">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.price" readonly @input="calculate(val)" />
                                    </td>
                                    <td style="min-width: 130px;">
                                        <div class="input-group input-info">
                                            <input type="number" min="0" class="form-control" @input="calculatePercent(val)" v-model.number="val.percent">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td style="min-width: 200px;">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model.number="val.discount" @input="calculateDiscount(val)" />
                                    </td>
                                    <td style="min-width: 200px;" class="text-right">{{ ((val.qty * val.price) - val.discount).toLocaleString('id-ID') }}</td>
                                    <td>
                                        <div class="text-center">
                                            <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="deleteProduct(index)"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0" v-if="listProduct.length > 0">
                    <button type="button" class="btn btn-outline-success float-right" @click="handleSubmit">
                        <i class="fa fa-save mr-1"></i> Create Consignment
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';
import {
    Validator
} from 'simple-vue-validator';
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: 'CreateConsignment',
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Consignment | %s',
    },
    data() {
        return {
            customers: [],
            sales_users: [],
            sales_user: '',
            products: [],
            listProduct: [],
            selected: [],
            product: {
                part_number: '',
                name: '',
                price: 0,
                discount: 0,
                percent: 0,
                principle_name: '',
                qty: 1,
                id_product: ''
            },
            formInput: {
                id_customer: '',
                note: '',
                no_so_cons: '',
                start_date: moment().format('YYYY-MM-DD'),
                end_date: '',
                id_sales: '',
                products: [],
            }
        }
    },
    validators: {
        'formInput.id_customer': function (value) {
            return Validator.value(value).required('Customer is required');
        },
        'formInput.no_so_cons': function (value) {
            return Validator.value(value).required('No. Sales Order Consignment is required');
        },
        'formInput.start_date': function (value) {
            return Validator.value(value).required('Start is required');
        },
        'formInput.end_date': function (value) {
            return Validator.value(value).required('End Date is required');
        },
        'formInput.id_sales': function (value) {
            return Validator.value(value).required('User Sales is required');
        },
    },
    async created() {
        this.getSalesUsers()
        this.requestNumberCONS()
    },
    computed: {
        calculateTotalAmount() {
            return (this.formInput.sub_total + this.formInput.shipping_charge - this.formInput.discount);
        },
    },
    methods: {
        async handleSalesUser(value) {
            if (value == null) {
                this.formInput.id_sales = '';
                return false;
            }
            this.formInput.id_sales = value.id;
        },
        async getSalesUsers() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(
                    'user-service/auth/users-by-role?role=sales&is_access=1', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.sales_users = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Users", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Sales Users", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },
        async requestNumberCONS() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'CONS');
                params.append('back_date', 0);
                params.append('date', moment().format('YYYY-MM-DD'));

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_so_cons = resp.data.data.number;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Number CONS", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Number CONS ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async calculatePercent(row) {
            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        async calculateDiscount(row) {
            row.percent = (parseFloat(row.discount || 0) / (parseFloat(row.price || 0) * parseFloat(row.qty || 0))) * 100;
        },
        async calculate(row) {
            if (row.qty == "" || row.price == "") return;

            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/customer", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.customers = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/product", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.products = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Products", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Products ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        setSelectedProduct(product) {
            if (product == null) {
                this.product = {
                    id_product: '',
                    name: '',
                    qty: this.product.qty
                }
                return;
            }

            this.product = {
                part_number: product.part_number,
                principle_name: product.principle_name,
                id_product: product.id,
                name: product.name,
                qty: this.product.qty,
                price: 0,
                discount: 0,
                percent: 0,
            }
        },
        async deleteProduct(index) {
            this.listProduct.splice(index, 1);
        },
        async handleOk() {
            if (this.product.id_product == "" || this.product.qty == "") {
                return false;
            }

            this.selected = [];

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/sales_order/sales/get_price', {
                        id_product: this.product.id_product
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.product.price = resp.data.data.price == null ? 0 : resp.data.data.price.selling_price
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Price", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Product Price", error.message, "error");
            } finally {

                this.listProduct.push(this.product);
                this.product = {
                    part_number: '',
                    name: '',
                    price: 0,
                    discount: 0,
                    percent: 0,
                    principle_name: '',
                    qty: 1,
                    id_product: '',
                };
                this.$store.dispatch("setLoading", false);
            }
        },

        // async handleUpload(id_so) {
        //     try {
        //         await this.$store.dispatch("setLoading", true);
        //         let resp = await axios.post(
        //             'dental-erp-service/sales_order/upload/po', {
        //                 id_ref: id_so,
        //                 file: this.file
        //             }, {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             this.$swal.fire("Success", resp.data.message, "success");
        //             window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
        //         }
        //     } catch (error) {
        //         if (error.response != undefined) {
        //             if (error.response.data != undefined) {
        //                 if (error.response.data.error_auth != undefined) {
        //                     this.$swal.fire("Error", error.response.data.message, "error");
        //                     this.$store.dispatch("logOut").then(() => {
        //                         this.$router.push("/login");
        //                     });
        //                     return;
        //                 }
        //                 return this.$swal.fire("Request ", error.response.data.message, "error");
        //             }
        //         }

        //         this.$sentry.captureException(error);
        //         this.$swal.fire("Error", error.message, "error");
        //     } finally {
        //         this.$store.dispatch("setLoading", false);
        //     }
        // },

        async handleSubmit() {

            let validation = await this.$validate();
            if (!validation) {
                return false;
            }

            if (this.listProduct.length == 0) {
                this.$swal.fire("Error", 'The list product can not be empty', "error");
                return;
            }

            this.formInput.total = this.formInput.total_final

            this.formInput.products = [];
            await this.listProduct.forEach(element => {
                for (let index = 0; index < parseInt(element.qty); index++) {
                    this.formInput.products.push({
                        id: element.id_product,
                        price: element.price,
                        qty: 1,
                        discount: element.discount / parseInt(element.qty),
                    })
                }
            });

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            'dental-erp-service/consignment/sales/store',
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            // if (this.file.length > 0) {
                            //     return this.handleUpload(resp.data.id);
                            // }

                            this.$swal.fire("Success", resp.data.message, "success");
                            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }

                }
            });

        },
        clearForm() {
            var self = this; //you need this because *this* will refer to Object.keys below`
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.formInput).forEach(function (key) {
                self.formInput[key] = '';
            });
        }
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
