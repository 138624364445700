var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"form-head d-flex mb-3 align-items-start"},[_c('div',{staticClass:"mr-auto d-none d-lg-block"}),_c('b-button',{attrs:{"variant":"outline-info","to":"demo-request"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"arrow-left","aria-hidden":"true","animation":"fade"}}),_vm._v(" Back to Demo Request List ")],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',{ref:"formAdd",staticClass:"mt-5",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-4 col-lg-3 col-xl-2"},[_c('label',{staticClass:"text text-info"},[_vm._v("Select Customer")]),_c('v-select',{attrs:{"label":"organization","filterable":false,"options":_vm.customers},on:{"input":_vm.setSelectedCustomer,"search":_vm.onSearchCustomer},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v(" "+_vm._s(option.organization)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.organization)+" ")])]}}]),model:{value:(_vm.formInput.customer),callback:function ($$v) {_vm.$set(_vm.formInput, "customer", $$v)},expression:"formInput.customer"}},[_c('template',{slot:"no-options"},[_vm._v(" type to search customer... ")])],2)],1),_c('div',{staticClass:"form-group col-md-4 col-lg-3 col-xl-2"},[_c('label',{staticClass:"text text-info"},[_vm._v("Start Date")]),_c('b-form-datepicker',{attrs:{"no-flip":"","date-format-options":{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        },"locale":"id"},model:{value:(_vm.formInput.start_date),callback:function ($$v) {_vm.$set(_vm.formInput, "start_date", $$v)},expression:"formInput.start_date"}}),_c('label',{staticClass:"text-danger error"},[_vm._v(_vm._s(_vm.validation.firstError('formInput.start_date')))])],1),_c('div',{staticClass:"form-group col-md-4 col-lg-3 col-xl-2"},[_c('label',{staticClass:"text text-info"},[_vm._v("End Date")]),_c('b-form-datepicker',{attrs:{"no-flip":"","date-format-options":{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        },"locale":"id","min":_vm.formInput.start_date},model:{value:(_vm.formInput.end_date),callback:function ($$v) {_vm.$set(_vm.formInput, "end_date", $$v)},expression:"formInput.end_date"}}),_c('label',{staticClass:"text-danger error"},[_vm._v(_vm._s(_vm.validation.firstError('formInput.end_date')))])],1)])]),_c('hr',{staticClass:"pb-2"}),(_vm.listProduct.length > 0)?_c('div',{staticClass:"pt-4 table-responsive"},[_c('table',{staticClass:"table text-center"},[_vm._m(1),_c('tbody',_vm._l((_vm.listProduct),function(val,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(val.part_number))]),_c('td',[_vm._v(_vm._s(val.name))]),_c('td',[_vm._v(" "+_vm._s(val.qty)+" ")])])}),0)])]):_vm._e()]),(_vm.listProduct.length > 0)?_c('div',{staticClass:"card-footer border-0 pt-0"},[_c('button',{staticClass:"btn btn-outline-success mb-1 mr-1 float-right",attrs:{"type":"button"},on:{"click":_vm.handleSubmit}},[_c('i',{staticClass:"fa fa-save  mr-1"}),_vm._v(" Update Demo Request")])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',[_c('h4',{staticClass:"card-title"},[_vm._v("Create Demo Request")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Part Number")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Qty")])])])}]

export { render, staticRenderFns }