<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <div class="dropdown custom-dropdown mr-2">
            <button type="button" class="btn btn-outline-primary d-flex align-items-center svg-btn" data-toggle="dropdown" aria-expanded="false" style="border-color: #2f4cdd !important">
                <svg width="16" class="scale5" height="16" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16647 27.9558C9.25682 27.9856 9.34946 28.0001 9.44106 28.0001C9.71269 28.0001 9.97541 27.8732 10.1437 27.6467L21.5954 12.2248C21.7926 11.9594 21.8232 11.6055 21.6746 11.31C21.526 11.0146 21.2236 10.8282 20.893 10.8282H13.1053V0.874999C13.1053 0.495358 12.8606 0.15903 12.4993 0.042327C12.1381 -0.0743215 11.7428 0.0551786 11.5207 0.363124L0.397278 15.7849C0.205106 16.0514 0.178364 16.403 0.327989 16.6954C0.477614 16.9878 0.77845 17.1718 1.10696 17.1718H8.56622V27.125C8.56622 27.5024 8.80816 27.8373 9.16647 27.9558ZM2.81693 15.4218L11.3553 3.58389V11.7032C11.3553 12.1865 11.7471 12.5782 12.2303 12.5782H19.1533L10.3162 24.479V16.2968C10.3162 15.8136 9.92444 15.4218 9.44122 15.4218H2.81693Z" fill="#2F4CDD" />
                </svg>
                <span class="fs-16 ml-3">Toggle Colums</span>
                <i class="fa fa-angle-down scale5 ml-3"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <button v-for="(column, index) in columnRender" :key="index" @click="changeColumn(column)" :class="
              column.hidden
                ? 'btn btn-xs btn-outline-warning btn-block'
                : 'btn btn-xs btn-outline-secondary btn-block'
            ">
                    {{ column.label }}
                </button>
            </div>
        </div>
        <b-button variant="outline-info" to="create-sales-order">
            <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Create Sales Order
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-block d-sm-flex">
                    <div>
                        <h4 class="card-title">Data Sales Order</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Detail" :to="'detail-sales-order-approval?id='+ props.row.id" variant="outline-info" size="sm"><i class="fa fa-search"></i> Detail </b-button>
                                    <!-- <b-button v-if="props.row.status_approve == 'Pending'" @click="handleApprove(props.row)" title="Approve" variant="outline-success" size="sm"><i class="fa fa-check"></i> Approve </b-button> -->
                                    <b-button v-if="props.row.status_approve == 'Pending'" @click="handleReject(props.row)" title="Reject" variant="outline-danger" size="sm"><i class="fa fa-check"></i> Reject </b-button>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'total_item'">
                                <b-button title="Detail product" variant="outline-secondary" size="sm" pill @click="detailItem('totalItem', props.row.products)" v-b-modal.modal-list-products>{{ props.row.products.length }}</b-button>
                            </div>
                            <div v-else-if="props.column.field == 'status_approve'">
                                <!-- <span class="btn btn-sm" :class="{'btn-info': props.row.status_approve == 'Pending', 'btn-danger': props.row.status_approve == 'Denied', 'btn-success': props.row.status_approve == 'Approved' }"> {{ props.row.status_approve }} </span> -->
                                <b-button-group>
                                    <span class="btn btn-sm" :class="{'btn-info': props.row.status_approve == 'Pending', 'btn-danger': props.row.status_approve == 'Denied', 'btn-success': props.row.status_approve == 'Approved' }"> {{ props.row.status_approve }} </span>
                                    <span class="btn btn-sm" :class="props.row.last_status == 'No Process' ? 'btn-info' : 'btn-success' ">{{ props.row.last_status }}</span>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'approve_by'">
                                <div v-if="props.row.approve_by !== null">
                                    <img :src="props.row.approve_by_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.approve_by_data.first_name }}</span>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-list-products" size="xl" title="List Product" button-size="sm">
        <div class="pt-4 table-responsive" v-if="listProduct.length > 0">
            <table class="table dark-table-bordered text-left">
                <thead class="thead-dark">
                    <tr>
                        <th>Part Number</th>
                        <th class="text-left">Name</th>
                        <th>Qty</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, index) in listProduct" :key="index">
                        <td>{{ val.part_number }}</td>
                        <td class="text-left">{{ val.product_name }}</td>
                        <td>{{ val.qty }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "SalesOrderApproval",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Sales Order Approval | %s',
    },
    data() {
        return {
            listProduct: [],
            columns: [{
                    label: "No. SO",
                    field: "no_so",
                    hidden: false,
                },
                {
                    label: "No. PO",
                    field: "no_po",
                    hidden: false,
                },
                {
                    label: "Customer",
                    field: "customer_name",
                    hidden: false,
                },
                {
                    label: "Approved Item",
                    field: "approved_item",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Unapproved Item",
                    field: "unapproved_item",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Total Item",
                    field: "total_item",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "DO Item",
                    field: "in_do_item",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "PR Item",
                    field: "in_pr_item",
                    thClass: 'text-center',
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Status",
                    field: "status_approve",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Approve By",
                    field: "approve_by",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Created Date",
                    field: "created_at",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-left text-nowrap",
                    sortable: false,
                },
            ],
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
            },
        };
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i => i.field != 'action')
        },
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        handleCancel() {
            this.propsEdit = {};
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        // handleApprove(row) {
        //     let id_product_so = row.products.map(val => {
        //         return {
        //             id_product_so: val.id
        //         }
        //     });

        //     this.$swal.fire({
        //         title: 'Are you sure approve this data?',
        //         text: "You won't be able to revert this!",
        //         icon: 'info',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Yes, Approve it!'
        //     }).then(async (result) => {
        //         if (result.isConfirmed) {
        //             try {
        //                 await this.$store.dispatch("setLoading", true);
        //                 let resp = await axios.post(
        //                     "dental-erp-service/sales_order/sales/approval", {
        //                         id: row.id,
        //                         products: id_product_so
        //                     }, {
        //                         headers: {
        //                             Authorization: localStorage.getItem("token") || "",
        //                         },
        //                     }
        //                 );

        //                 if (resp.data.error === false) {
        //                     this.$swal.fire("Success", resp.data.message, "success");
        //                     this.getData();
        //                 }

        //             } catch (error) {
        //                 if (error.response != undefined) {
        //                     if (error.response.data != undefined) {
        //                         if (error.response.data.error_auth != undefined) {
        //                             this.$swal.fire("Error", error.response.data.message, "error");
        //                             this.$store.dispatch("logOut").then(() => {
        //                                 this.$router.push("/login");
        //                             });
        //                             return;
        //                         }
        //                         return this.$swal.fire("Request ", error.response.data.message, "error");
        //                     }
        //                 }

        //                 this.$sentry.captureException(error);
        //                 this.$swal.fire("Error", error.message, "error");
        //             } finally {
        //                 this.$store.dispatch('setLoading', false);
        //             }
        //         }
        //     });
        // },

        handleReject(row) {

            this.$swal.fire({
                title: 'Are you sure reject this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Reject it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            "dental-erp-service/sales_order/sales/denial", {
                                id: row.id,
                                products: []
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getData();
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/sales_order/sales", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async detailItem(action, row) {

            let arr = [];

            if (action == 'totalItem') {
                this.titleModal = 'Total Item';
                this.actionModal = 'total_item';
                // each every products
                await row.forEach(function (value, index) {
                    if (index == 0) {
                        arr.push({
                            part_number: value.part_number,
                            product_name: value.product_name,
                            qty: 1,
                        });
                    } else {
                        let found = false;

                        for (let i = 0; i < arr.length; i++) {
                            const element = arr[i];

                            if (element.part_number == value.part_number) {
                                arr[i].qty += 1;
                                found = true;
                                break;
                            }
                        }

                        if (found === false) {
                            arr.push({
                                part_number: value.part_number,
                                product_name: value.product_name,
                                qty: 1,
                                price: value.price,
                                discount: value.discount,
                            });
                        }
                    }
                });
                return this.listProduct = arr;
            }
        },
        async deleteData(row) {

            this.$swal.fire("Info", 'Cooming soon', "info");

            console.log(row);
            // this.$swal.fire({
            //     title: 'Are you sure delete this data?',
            //     text: "You won't be able to revert this!",
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Yes, delete it!'
            // }).then(async (result) => {
            //     if (result.isConfirmed) {
            //         try {
            //             await this.$store.dispatch("setLoading", true);
            //             let resp = await axios.delete(
            //                 "dental-erp-service/area/destroy", {
            //                     headers: {
            //                         Authorization: localStorage.getItem("token") || "",
            //                     },
            //                     data: {
            //                         id: row.id
            //                     }
            //                 }
            //             );

            //             if (resp.data.error === false) {
            //                 this.$swal.fire("Success", resp.data.message, "success");
            //                 this.getData();
            //             }

            //         } catch (error) {
            //             this.$swal.fire("Error", error.response.data.message, "error");
            //             console.log(error);
            //         } finally {
            //             await this.$store.dispatch("setLoading", false);
            //         }
            //     }
            // });
        },
        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
