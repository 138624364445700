<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <b-button variant="outline-info" @click="filterData = !filterData">
            Filter Data
            <b-icon-arrow-bar-down></b-icon-arrow-bar-down>
        </b-button>
        <div class="mr-auto d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <div class="dropdown custom-dropdown mr-2">
            <button type="button" class="btn btn-outline-primary d-flex align-items-center svg-btn" data-toggle="dropdown" aria-expanded="false" style="border-color: #2f4cdd !important">
                <svg width="16" class="scale5" height="16" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16647 27.9558C9.25682 27.9856 9.34946 28.0001 9.44106 28.0001C9.71269 28.0001 9.97541 27.8732 10.1437 27.6467L21.5954 12.2248C21.7926 11.9594 21.8232 11.6055 21.6746 11.31C21.526 11.0146 21.2236 10.8282 20.893 10.8282H13.1053V0.874999C13.1053 0.495358 12.8606 0.15903 12.4993 0.042327C12.1381 -0.0743215 11.7428 0.0551786 11.5207 0.363124L0.397278 15.7849C0.205106 16.0514 0.178364 16.403 0.327989 16.6954C0.477614 16.9878 0.77845 17.1718 1.10696 17.1718H8.56622V27.125C8.56622 27.5024 8.80816 27.8373 9.16647 27.9558ZM2.81693 15.4218L11.3553 3.58389V11.7032C11.3553 12.1865 11.7471 12.5782 12.2303 12.5782H19.1533L10.3162 24.479V16.2968C10.3162 15.8136 9.92444 15.4218 9.44122 15.4218H2.81693Z" fill="#2F4CDD" />
                </svg>
                <span class="fs-16 ml-3">Toggle Colums</span>
                <i class="fa fa-angle-down scale5 ml-3"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <button v-for="(column, index) in columnRender" :key="index" @click="changeColumn(column)" :class="
              column.hidden
                ? 'btn btn-xs btn-outline-warning btn-block'
                : 'btn btn-xs btn-outline-secondary btn-block'
            ">
                    {{ column.label }}
                </button>
            </div>
        </div>
        <b-button variant="outline-info" to="create-quotation">
            <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Create Quotation
        </b-button>
    </div>
    <div v-if="filterData == true" class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-block d-sm-flex">
                    <div>
                        <h4 class="card-title">Filter Data</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text text-info">Start Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="paramQuery.start_date">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text text-info">End Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="paramQuery.end_date" :min="paramQuery.start_date">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-6 col-lg-2">
                                <label class="text">Approved?</label>
                                <v-select label="name" :clearable="false" :filterable="false" :options="approval" v-model="paramQuery.approved" :reduce="approval => approval.value">
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.text }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.text }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-12 col-lg-12">
                                <b-button variant="outline-info" @click="getData">
                                    Filter
                                </b-button>
                                <b-button class="ml-3" variant="outline-danger" @click="clearInput">
                                    Clear
                                </b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-block d-sm-flex">
                    <div>
                        <h4 class="card-title">Data Quotation</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button v-if="props.row.approved == 0" title="Update" :to="'update-quotation?id='+ props.row.id" variant="outline-warning" size="sm"><i class="fa fa-pencil"></i> Update </b-button>
                                    <b-button title="Detail" :to="'detail-quotation?id='+ props.row.id" variant="outline-info" size="sm"><i class="fa fa-search"></i> Detail </b-button>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'status_approve'">
                                <span class="btn btn-sm" :class="{'btn-info': props.row.status_approve == 'Pending', 'btn-danger': props.row.status_approve == 'Denied', 'btn-success': props.row.status_approve == 'Approved' }"> {{ props.row.status_approve }} </span>
                            </div>
                            <div v-else-if="props.column.field == 'customer.organization'">
                                <span class="w-space-no">{{ props.row.customer.organization }} <span class="badge badge-xs badge-info"> {{ props.row.customer.status }} </span></span>
                            </div>
                            <div v-else-if="props.column.field == 'approve_by'">
                                <div v-if="props.row.approve_by !== null">
                                    <img :src="props.row.approve_by_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.approve_by_data.first_name }}</span>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "Quotation",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Quotation | %s',
    },
    data() {
        return {
            listProduct: [],
            columns: [{
                    label: "No Quotation",
                    field: "no_quotation",
                    hidden: false,
                },
                {
                    label: "Customer",
                    field: "customer.organization",
                    hidden: false,
                },
                {
                    label: "Date",
                    field: "detail.quotation_date",
                    hidden: false,
                },
                {
                    label: "Approve",
                    field: "approve_by",
                    thClass: 'text-left',
                    tdClass: 'text-left text-nowrap',
                    hidden: false,
                },
                {
                    label: "Created Date",
                    field: "created_at",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            approval: [{
                    text: "Yes",
                    value: 1
                },
                {
                    text: "No",
                    value: 0
                }
            ],
            filterData: false,
            timeout: null,
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
            },
        };
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i => i.field != 'action')
        },
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        clearInput() {
            this.paramQuery = {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                id_principle: "",
                id_product: "",
                start_date: "",
                end_date: "",
                approved: ""
            }
            this.getData();
        },

        handleCancel() {
            this.propsEdit = {};
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });

            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                self.getData();
            }, 1000);
            // this.getData();
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/quotation/sales", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async detailItem(action, row) {

            let arr = [];

            if (action == 'totalItem') {
                this.titleModal = 'Total Item';
                this.actionModal = 'total_item';
                // each every products
                await row.forEach(function (value, index) {
                    if (index == 0) {
                        arr.push({
                            part_number: value.part_number,
                            product_name: value.product_name,
                            qty: 1,
                        });
                    } else {
                        let found = false;

                        for (let i = 0; i < arr.length; i++) {
                            const element = arr[i];

                            if (element.part_number == value.part_number) {
                                arr[i].qty += 1;
                                found = true;
                                break;
                            }
                        }

                        if (found === false) {
                            arr.push({
                                part_number: value.part_number,
                                product_name: value.product_name,
                                qty: 1,
                                price: value.price,
                                discount: value.discount,
                            });
                        }
                    }
                });
                return this.listProduct = arr;
            }
        },
        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
