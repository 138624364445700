<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <button class="btn btn-outline-info" @click="handleBackRoute">
            <span class="fa fa-arrow-left mr-2"></span> Back to Sales Order List
        </button>
    </div>
    <div class="row" v-if="sales_order.customer !== undefined">
        <div class="col-xl-9 col-xxl-10 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div :class="handleClassByApprove">
                        <div class="card-body">
                            <div class="row mx-0">
                                <div class="media align-items-center col-md-4 col-lg-3 col-xl-2 px-0  mb-md-0">
                                    <img class="mr-3 img-fluid rounded-circle" width="65" src="/assets/images/delivery.png" alt="DexignZone">
                                    <div class="media-body">
                                        <h4 class="my-0 text-black"> {{ sales_order.customer.organization }}</h4>
                                        <small>{{ sales_order.customer.phone_number }}</small>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No Sales Order</small>
                                    <p class="text-detail-css">{{sales_order.no_so}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">No Purchase Order</small>
                                    <p class="text-detail-css">{{sales_order.no_po}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Order Type</small>
                                    <p class="text-detail-css">{{sales_order.order_type}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Payment type</small>
                                    <p class="text-detail-css">{{sales_order.detail.payment_type}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css">Currency</small>
                                    <p class="text-detail-css">{{sales_order.detail.currency}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <h4 class="card-title">Product List</h4>
                                </div>
                                <div class="col-md-6 col-sm-12 text-right mt-3  ">
                                    <button class="btn btn-sm btn-info mr-3" v-if="canApprove" @click="handleApprove()"> Approve Selected Item </button>
                                    <button class="btn btn-sm btn-danger" v-b-modal.modal-revise> Request Revise </button>
                                    <b-button class="btn btn-sm btn-warning ml-3" :to="'revise-sales-order?id=' + $route.query.id"> Revise SO </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div id="accordion-two" class="accordion accordion-primary">
                                <div class="accordion__item">
                                    <div class="accordion__header" data-toggle="collapse" data-target="#bordered_collapseOne"> <span class="accordion__header--text">Product in SO</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseOne" class="collapse accordion__body show" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left"> </th>
                                                            <th class="text-left">Status</th>
                                                            <th class="text-left">Part Number</th>
                                                            <th class="text-left">Product Name</th>
                                                            <th>QTY</th>
                                                            <th>Price</th>
                                                            <th>Discount</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(value, index) in shelter" :key="index">
                                                            <td>
                                                                <b-form-checkbox name="checkbox" :value="1" v-model="value.is_checked" :unchecked-value="0" v-if="canApprove && value.approved == 0"></b-form-checkbox>
                                                            </td>
                                                            <td>
                                                                <!-- <b-form-checkbox name="checkbox" :value="1" v-model="value.is_checked" :unchecked-value="0" v-if="sales_order.approved == 0 && canApprove && value.approved == 0"></b-form-checkbox> -->

                                                                <span v-if="value.approved == 1" class="btn btn-sm btn-outline-success"> Approved </span>

                                                                <span v-else-if="value.approved == 2" class="btn btn-sm btn-outline-danger"> Rejected </span>

                                                                <span v-else class="btn btn-sm btn-outline-warning"> Waiting </span>
                                                            </td>
                                                            <td class="text-left">{{ value.part_number }}</td>
                                                            <td class="text-left">{{ value.product_name }} <span v-if="value.is_bonus == 1" class="badge badge-xs badge-info">Bonus</span>
                                                            </td>
                                                            <td>{{ value.qty }}</td>
                                                            <td>{{ value.price.toLocaleString('id-ID') }}</td>
                                                            <td>{{ value.discount !== null ? value.discount.toLocaleString('id-ID') : 0 }}</td>
                                                            <td>{{ ((value.price * value.qty) - value.discount).toLocaleString('id-ID') }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" class="text-right">Sub Total</td>
                                                            <td>{{ sales_order.detail.sub_total.toLocaleString('id-ID') }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" class="text-right">Shipping Charge</td>
                                                            <td>{{ sales_order.detail.shipping_charge !== null ? parseFloat(sales_order.detail.shipping_charge).toLocaleString('id-ID') : 0 }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" class="text-right">Discount</td>
                                                            <td>{{ sales_order.detail.discount !== null ? sales_order.detail.discount.toLocaleString('id-ID') : 0 }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" class="text-right">Tax</td>
                                                            <td>{{ sales_order.detail.tax_value.toLocaleString('id-ID') }}</td>
                                                            <td>
                                                                <span v-if="sales_order.detail.include_tax == 1" style="color: #32CD32">
                                                                    <!-- <i :class="sales_order.detail.include_tax == 1 ? 'fa fa-lg fa-check-circle' : 'fa fa-lg fa-times-circle'"></i> -->
                                                                    <i class="fa fa-lg fa-check-circle"></i>
                                                                </span>
                                                                <span v-else style="color: #D21404">
                                                                    <i class="fa fa-lg fa-times-circle"></i>
                                                                </span>
                                                                <span v-if="sales_order.detail.include_tax == 1"> Tax Included</span>
                                                                <span v-else> Tax not Included</span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="5" class="text-right">Amount Due</td>
                                                            <td>{{ sales_order.detail.total.toLocaleString('id-ID') }}</td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td colspan="5" class="text-left">
                                                                <button class="btn btn-sm btn-info"> Approve Selected Item </button>
                                                            </td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion__item" v-if="product_pr.length > 0">
                                    <div class="accordion__header collapsed" data-toggle="collapse" data-target="#bordered_collapseTwo"> <span class="accordion__header--text">Product in PR</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseTwo" class="collapse accordion__body" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Part Number</th>
                                                            <th class="text-left">Product Name</th>
                                                            <th>Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(value, index) in product_pr" :key="index">
                                                            <td class="text-left">{{ value.part_number }}</td>
                                                            <td class="text-left">{{ value.product_name }}</td>
                                                            <td>{{ value.qty }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion__item" v-if="product_po.length > 0">
                                    <div class="accordion__header collapsed" data-toggle="collapse" data-target="#bordered_collapseThree"> <span class="accordion__header--text">Product in PO</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseThree" class="collapse accordion__body" data-parent="#accordion-two">
                                        <div class="accordion__body--text" v-for="(value, index) in product_po" :key="index">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="3" class="text-center text-info"> {{ value.no_po }} </th>
                                                        </tr>
                                                        <tr>
                                                            <th class="text-left" style="width: 15%">Part Number</th>
                                                            <th class="text-left">Product Name</th>
                                                            <th style="width: 10%">Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(v, i) in value.get_products" :key="i">
                                                            <td class="text-left">{{ v.part_number }}</td>
                                                            <td class="text-left">{{ v.name }}</td>
                                                            <td>{{ v.qty }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion__item" v-if="product_ri.length > 0">
                                    <div class="accordion__header collapsed" data-toggle="collapse" data-target="#bordered_collapseFour"> <span class="accordion__header--text">Receive Item</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseFour" class="collapse accordion__body" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left">Part Number</th>
                                                            <th class="text-left">Product Name</th>
                                                            <th>Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(value, index) in product_ri" :key="index">
                                                            <td class="text-left">{{ value.part_number }}</td>
                                                            <td class="text-left">{{ value.product_name }}</td>
                                                            <td>{{ value.qty }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion__item" v-if="sales_order.revise_submission.length > 0">
                                    <div class="accordion__header collapsed" data-toggle="collapse" data-target="#bordered_collapseFive"> <span class="accordion__header--text">Revise Submission</span>
                                        <span class="accordion__header--indicator"></span>
                                    </div>
                                    <div id="bordered_collapseFive" class="collapse accordion__body" data-parent="#accordion-two">
                                        <div class="accordion__body--text">
                                            <div class="table-responsive">
                                                <table class="table table-responsive-m">
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th class="text-left">Note</th>
                                                            <th class="text-left">Submitted by</th>
                                                            <th class="text-left">Submitted at</th>
                                                            <th class="text-left">Status</th>
                                                            <th class="text-left">Approved by</th>
                                                            <th v-if="canApprove">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(value, index) in sales_order.revise_submission" :key="index">
                                                            <td class="text-left">{{index + 1}}</td>
                                                            <td class="text-left text-wrap" style="width:30%">{{value.note}}</td>
                                                            <td class="text-left">
                                                                <img :src="value.submissioner_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                                                <span class="w-space-no">{{ value.submissioner_data.first_name }} </span>
                                                                <span class="w-space-no">{{ value.submissioner_data.last_name }}</span>
                                                            </td>
                                                            <td class="text-left">{{value.date_submission}}</td>
                                                            <td class="text-left">
                                                                <span class="btn btn-sm light" :class="value.approved == 0 ? 'btn-danger' : 'btn-success'">{{value.approved == 0 ? 'Unapproved' : 'Approved'}}</span>
                                                            </td>
                                                            <td class="text-left" v-if="value.approved != 0">
                                                                <img :src="value.approve_by_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                                                <span class="w-space-no">{{ value.approve_by_data.first_name }} </span>
                                                                <span class="w-space-no">{{ value.approve_by_data.last_name }}</span>
                                                            </td>
                                                            <td class="text-left" v-else>
                                                                <span class="w-space-no">-</span>
                                                            </td>
                                                            <td v-if="canApprove">
                                                                <b-button class="btn btn-sm btn-info" @click="approveRevise(value.id)">Approve</b-button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-xxl-2 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card h-auto">
                        <div class="card bg-secondary sticky mb-0">
                            <div class="card-header border-0 pb-0">
                                <h5 class="card-title text-white mt-2">Note Order</h5>
                            </div>
                            <div class="card-body pt-3">
                                <p class="fs-14 op7 text-white">{{ sales_order.note }} </p>
                            </div>
                            <div class="card-footer border-0 py-4 bg-primary">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="media align-items-center">
                                            <img class="mr-3 img-fluid rounded-circle" width="50" style="object-fix: cover;" :src="sales_order.create_by_data.profile" alt="DexignZone">
                                            <div class="media-body">
                                                <small class="text-white">Create By :</small>
                                                <h5 class="my-0 text-white font-weight-bold"> {{ sales_order.create_by_data.first_name }}</h5>
                                                <small class="text-white">{{ sales_order.create_by_data.title }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-4" v-if="sales_order.id_sales !== null">
                                        <div class="media align-items-center">
                                            <img class="mr-3 img-fluid rounded-circle" width="50" style="object-fix: cover;" :src="sales_order.sales_by_data.profile" alt="DexignZone">
                                            <div class="media-body">
                                                <small class="text-white">Sales User :</small>
                                                <h5 class="my-0 text-white font-weight-bold"> {{ sales_order.sales_by_data.first_name }}</h5>
                                                <small class="text-white">{{ sales_order.sales_by_data.title }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card">
                        <div class="card-header border-0 pb-0">
                            <h4 class="card-title">History</h4>
                        </div>
                        <div class="card-body">
                            <div class="widget-timeline-icon">
                                <ul class="timeline">
                                    <li class="pb-5" v-if="timeline.info_so !== undefined">
                                        <div class="icon bg-warning"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">Created SO</h4>
                                            <p class="fs-15 mb-0 ">{{ timeline.info_so.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li class="pb-5" v-if="sales_order.id_sales !== null">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">Sales User</h4>
                                            <img :src="sales_order.sales_by_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                            <span class="font-weight-bold">{{ sales_order.sales_by_data.first_name }}</span>
                                        </a>
                                    </li>
                                    <li class="pb-5" v-if="timeline.info_so !== undefined">
                                        <div class="icon bg-primary"></div>
                                        <div v-if="timeline.info_so.status == 'Approved'">
                                            <a class="timeline-panel text-muted" href="javascript:void(0);">
                                                <h4 class="mb-2">{{ timeline.info_so.status }}</h4>
                                                <div v-if="sales_order.approve_by_data != null">
                                                    <img :src="sales_order.approve_by_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                                    <span class="font-weight-bold">{{ sales_order.approve_by_data.first_name }}</span>
                                                </div>
                                                <div v-else>
                                                    <span class="font-weight-bold">BY SYSTEM</span>
                                                </div>
                                                <p class="fs-15 mb-0 ">{{ timeline.info_so.updated_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                            </a>
                                            <br>
                                        </div>
                                        <div v-else>
                                            <a class="timeline-panel text-muted" href="javascript:void(0);">
                                                <h4 class="mb-2">{{ timeline.info_so.status }}</h4>
                                                <p class="fs-15 mb-0 ">{{ timeline.info_so.updated_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                            </a>
                                        </div>
                                    </li>
                                    <li class="pb-5" v-for="(v, i) in sales_order.log_approval" :key="'log-approval-' +i">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">{{ v.approved == 1 ? 'Approved' : 'Rejected' }}</h4>
                                            <img :src="v.approve_by_data.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                            <span class="font-weight-bold">{{ v.approve_by_data.first_name }}</span>
                                            <p class="fs-15 mb-0 ">{{ v.act_time | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li class="pb-5" v-if="timeline.info_pr.no_pr !== undefined">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">PR: {{ timeline.info_pr.no_pr }}</h4>
                                            <p class="fs-15 mb-0 ">{{ timeline.info_pr.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li class="pb-5" v-for="(v, i) in timeline.info_po" :key="'po' + i">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">PO: {{v.no_po}}</h4>
                                            <p class="fs-15 mb-0 ">{{ v.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li class="pb-5" v-for="(v, i) in timeline.info_receive" :key="'re' + i">
                                        <div class="icon bg-success"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">Receive: {{v.product_recieve}} Items</h4>
                                            <p class="fs-15 mb-0 ">{{ v.incoming_date | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li class="pb-5" v-for="(v, i) in timeline.info_do" :key="'do' + i">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">DO : {{v.no_do}} <br> {{v.item_deliver}} Items</h4>
                                            <p class="fs-15 mb-0 ">{{ v.created_at | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                    <li class="pb-5" v-for="(v, i) in timeline.info_payment" :key="'pay' + i">
                                        <div class="icon bg-primary"></div>
                                        <a class="timeline-panel text-muted" href="javascript:void(0);">
                                            <h4 class="mb-2">INV : {{v.no_invoice}} <span class="badge badge-sm" :class="v.status == 'Paid' ? 'badge-success' : 'badge-info'">{{v.status}}</span></h4>
                                            <p class="fs-15 mb-0 ">{{ v.payment_date | moment('dddd, DD-MM-YYYY, hh:mm:ss') }}</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card h-auto">
                        <div class="card bg-secondary sticky mb-0">
                            <div class="card-footer border-0 py-3" v-for="(v, i) in sales_order.uploaded_po" :key="i">
                                <div class="media align-items-center">
                                    <a :href="v.file_url" target="_blank" class="my-0 text-white"> File {{ i + 1 }} </a>
                                    <!-- <div class="media-body">
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-revise" ref="my-modal-revise" title="Form Request Revise" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info">Enter Note</label>
                    <input type="text" class="form-control" required v-model="formRevise.note" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "Detail",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Sales Order | %s',
    },
    data() {
        return {
            selected: [],
            totalApproved: 0,
            products: [],
            sales_order: {},
            product_pr: [],
            product_ri: [],
            product_po: [],
            product_do: [],
            formInput: {
                no_po: '',
                no_so: '',
                id_sales_order: '',
                customer_name: '',
                products: []
            },
            formInputApprove: {
                id: '',
                products: []
            },
            formRevise: {
                id: this.$route.query.id,
                note: ''
            },
            shelter: [],
            shelterIDProductSO: [],
            timeline: {},
        };
    },
    computed: {
        handleClassByApprove() {
            if (this.sales_order.approved == 1) {
                return 'card bg-success text-white'
            } else if (this.sales_order.approved == 2) {
                return 'card bg-danger text-white'
            }
            return 'card'
        },
        canApprove() {
            return this.$store.getters.canApproveSO;
        },
    },
    async created() {
        this.onLoad();
        this.getTimeline();
        this.getProductSO();
        this.getProductPO();
        this.getProductRI();
        this.getProductDO();
    },
    methods: {

        async handleRevise() {
            this.$swal.fire({
                title: 'Request Revise?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, revise it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post('dental-erp-service/sales_order/sales/revise_submission',
                            this.formRevise, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            });
                        if (resp.data.error === false) {
                            await this.$bvModal.hide('modal-revise');
                            this.formRevise.note = '';
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                            this.getTimeline();
                            this.getProductSO();
                            this.getProductPO();
                            this.getProductRI();
                            this.getProductDO();
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }
                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },

        async handleApprove() {

            const filterData = this.shelter.filter(product => product.is_checked === 1)
                .map(parent => {
                    return parent.id_product_so.map(child => {
                        return {
                            id_product_so: child
                        }
                    })
                })

            const mergedData = [].concat.apply([], filterData)

            if (mergedData.length == 0) {
                return this.$swal.fire("Error", 'Choose item to approve', "error");
            }

            const formData = {
                id: this.formInputApprove.id,
                products: mergedData
            }

            this.$swal.fire({
                title: 'Approve Sales Order?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, approve it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post('dental-erp-service/sales_order/sales/approval',
                            formData, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );
                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                            this.getTimeline();
                            this.getProductSO();
                            this.getProductPO();
                            this.getProductRI();
                            this.getProductDO();
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }
                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },

        async approveRevise(id_revise) {

            const formData = {
                id: id_revise,
                approved: true
            }

            this.$swal.fire({
                title: 'Approve Submission?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, approve it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post('dental-erp-service/sales_order/sales/revise_submission/approval',
                            formData, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );
                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.onLoad();
                            this.getTimeline();
                            this.getProductSO();
                            this.getProductPO();
                            this.getProductRI();
                            this.getProductDO();
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }
                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },

        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                    // return this.$router.push('sales-order');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    let totalApproved = 0;
                    this.products = resp.data.data.get_product_sos;

                    // each every products
                    await resp.data.data.get_product_sos.concat(resp.data.data.bonus_product).forEach(function (value, index) {

                        totalApproved += value.approved == 1 ? 1 : 0

                        if (index == 0) {
                            arr.push({
                                part_number: value.part_number,
                                product_name: value.product_name,
                                price: value.price,
                                discount: value.discount,
                                approved: value.approved,
                                id_product_so: [value.id],
                                qty: 1,
                                qty_approve: 1,
                                approve: value.approved == 1 ? 1 : 0,
                                is_bonus: value.is_bonus,
                                is_checked: 0
                            });
                        } else {

                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i].part_number == value.part_number && arr[i].is_bonus == value.is_bonus) {
                                    found = true;
                                    arr[i].qty += 1;
                                    arr[i].qty_approve += 1;
                                    arr[i].discount += value.discount;
                                    arr[i].id_product_so.push(value.id);
                                    arr[i].approve += value.approved == 1 ? 1 : 0

                                    break;
                                }
                            }

                            if (found === false) {
                                arr.push({
                                    part_number: value.part_number,
                                    product_name: value.product_name,
                                    price: value.price,
                                    discount: value.discount,
                                    approved: value.approved,
                                    id_product_so: [value.id],
                                    qty: 1,
                                    qty_approve: 1,
                                    approve: value.approved == 1 ? 1 : 0,
                                    is_bonus: value.is_bonus,
                                    is_checked: 0
                                });
                            }
                        }
                    });

                    this.shelter = arr.sort((a, b) => a.is_bonus - b.is_bonus);
                    this.totalApproved = totalApproved;

                    this.formInputApprove.id = resp.data.data.id
                    // console.log(resp.data.data)
                    return this.sales_order = resp.data.data;
                }

                // return this.$router.push('sales-order');
                return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getTimeline() {
            try {
                if (!this.$route.query.id) {
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }

                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/timeline", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.timeline = resp.data.data;
                }

                return this.$router.push('sales-order');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getProductSO() {
            try {
                if (!this.$route.query.id) {

                    // return this.$router.push('sales-order');
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }

                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/find_pr", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.product_pr = resp.data.data.get_products || [];
                }

                // return this.$router.push('sales-order');
                return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getProductPO() {
            try {
                if (!this.$route.query.id) {
                    // return this.$router.push('sales-order');
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }

                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/find_po", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.product_po = resp.data.data || [];
                }

                // return this.$router.push('sales-order');
                return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getProductRI() {
            try {
                if (!this.$route.query.id) {
                    // return this.$router.push('sales-order');
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }

                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/find_receive", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.product_ri = resp.data.data.items_receive || [];
                }

                // return this.$router.push('sales-order');
                return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async getProductDO() {
            try {
                if (!this.$route.query.id) {
                    // return this.$router.push('sales-order');
                    return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
                }

                const resp = await axios.post(
                    "dental-erp-service/sales_order/sales/find_do", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.product_do = resp.data.data || [];
                }

                // return this.$router.push('sales-order');
                return window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleRevise();
        },
        onRowSelected(items) {
            this.selected = items
        },
        handleBackRoute() {
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.text-detail-css {
    margin: 0;
    color: #484848;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
}

.text-title-css {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
}
</style>
