<template>
<b-modal id="modal-return-item" size="xl" title="Select Item to Return" button-size="sm" @ok="handleOk">
    <div class="pt-4 table-responsive">
        <div class="basic-form">
            <form class="form-inline">
                <div class="form-group mb-3">
                    <b-form-checkbox id="transit" v-model.number="formInput.transit" name="checkbox-1" :value="1" :unchecked-value="0"> </b-form-checkbox>
                    <label for="transit">Transit</label>
                </div>
            </form>
        </div>
        <div class="basic-form">
            <div class="form-row" v-if="formInput.transit === 1">
                <div class="form-group col-md-6 col-lg-4">
                    <label>Transit Date</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.transit_date">
                    </b-form-datepicker>
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label>Warehouse</label>
                    <v-select label="name" :filterable="false" :options="warehouses" v-model="warehouse" @search="onSearchWarehouse" @input="setSelectedWarehouse">
                        <template slot="no-options"> type to search warehouse... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>

            <div class="form-row" v-else>
                <div class="form-group col-md-6 col-lg-4">
                    <label>Return Date</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.return_date">
                    </b-form-datepicker>
                </div>
            </div>

        </div>
        <table class="table dark-table-bordered text-left">
            <thead class="thead-dark">
                <tr>
                    <th>Part Number</th>
                    <th class="text-left">Name</th>
                    <th>Return</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(val, index) in formInput.products" :key="index">
                    <td>{{ val.part_number }}</td>
                    <td class="text-left">{{ val.product_name }}</td>
                    <td>
                        <b-button v-if="val.selected" title="Remove" @click="selectItem(val)" class="mr-1" variant="outline-danger" pill size="sm"><i class="fa fa-minus"></i></b-button>
                        <b-button v-else title="Select" @click="selectItem(val)" class="mr-1" variant="outline-success" pill size="sm"><i class="fa fa-check-circle"></i></b-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</b-modal>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require('moment');

export default {
    name: 'TodoReturn',
    data() {
        return {
            formInput: {
                return_date: moment().format('YYYY-MM-DD'),
                transit_date: moment().format('YYYY-MM-DD'),
                id: '',
                id_transit_warehouse: '',
                customer_name: '',
                products: [],
                transit: 0
            },
            warehouses: [],
            warehouse: ''
        }
    },
    props: {
        propsFormInput: {
            type: Object,
            required: true,
        },
    },
    watch: {
        propsFormInput: {
            immediate: true,
            handler() {
                if (this.propsFormInput.id !== undefined) {
                    this.formInput.id = this.propsFormInput.id
                    this.formInput.customer_name = this.propsFormInput.customer_name
                    this.formInput.products = this.propsFormInput.products
                }
            }
        }
    },
    methods: {
        async handleOk() {

            this.formInput.products = this.formInput.products.filter(val => val.selected)

            if (this.formInput.products.length == 0) {
                return this.$swal.fire("Error", "Please Select Product", "error");
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/consignment/sales/todo_return',
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.$parent.onLoad()
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async selectItem(item) {
            item.selected = !item.selected
            
        },
        async onSearchWarehouse(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/warehouse", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.warehouses = resp.data.data;
                }
            } catch (error) {
                if (typeof error.response.data != undefined) {
                    return await this.$toastr.e(
                        error.response.data.message,
                        "Request Warehouse"
                    );
                }
                return await this.$toastr.e(error, "Request Warehouse");
            }
        },
        async setSelectedWarehouse(value) {

            if (value == null) {
                this.formInput.id_transit_warehouse = ''
                return;
            }

            this.formInput.id_transit_warehouse = value.id
        },
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
