<template>
<div class="container-fluid">
    <!-- <MenuApp /> -->
    <div class="row mt-5">
        <div class="col-lg-12 col-xl-12 col-md-12">
            <div class="row">
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Timessheet.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{totalSO}}</span>
                                    </h3>
                                    <p class="mb-0">Created</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Asset-Management.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{totalDelivered}}</span>
                                    </h3>
                                    <p class="mb-0">Delivered</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Digital-Document.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{approved}}</span>
                                    </h3>
                                    <p class="mb-0">Approved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Task.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{denied}}</span>
                                    </h3>
                                    <p class="mb-0">Denied</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div style="height: 810px;" class="col-xl-6">
            <div class="card">
                <div class="card-header bg-success border-0 pb-0 d-sm-flex flex-wrap d-block">
                    <div class="mb-3">
                        <h4 class="card-title text-white mb-1">Best Selling Products</h4>
                    </div>
                    <div class="card-action card-tabs mb-3">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#all1" role="tab">All</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#monthly1" role="tab">Monthly</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#weekly1" role="tab">Weekly</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#annually1" role="tab">Annually</a></li>
                        </ul>
                    </div>
                </div>
                <div style="height:100%;overflow:auto;text-align:center;" class="card-body tab-content mt-3">
                    <div class="tab-pane fade" id="monthly1">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-items2Content">
                            <div v-for="(bestproduct, index) in BestProductMonthlyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div style="justify-content: center;" class="media mb-4">
                                    <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                    <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="weekly1">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-items3Content">
                            <div v-for="(bestproduct, index) in BestProductWeeklyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div style="justify-content: center;" class="media mb-4">
                                    <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                    <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show active" id="all1">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-itemsContent">
                            <div v-for="(bestproduct, index) in BestProductAllList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div style="justify-content: center;" class="media mb-4">
                                    <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                    <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="annually1">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-items4Content">
                            <div v-for="(bestproduct, index) in BestProductAnnuallyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div style="justify-content: center;" class="media mb-4">
                                    <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                    <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                </div>
                                <div class="info">
                                    <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div style="height: 810px;" class="col-xl-6">
            <div class="card">
                <div class="card-header bg-success border-0 pb-0 d-sm-flex flex-wrap d-block">
                    <div class="mb-3">
                        <h4 class="card-title text-white mb-1">Best Customer</h4>
                    </div>
                    <div class="card-action card-tabs mb-3">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#allcustomer" role="tab">All</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#monthlycustomer" role="tab">Monthly</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#weeklycustomer" role="tab">Weekly</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#annuallycustomer" role="tab">Annually</a></li>
                        </ul>
                    </div>
                </div>
                <div style="height:100%;overflow:auto;text-align:center;" class="card-body tab-content mt-3">
                    <div class="tab-pane fade" id="monthlycustomer">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-items2Content">
                            <div v-for="(bestcustomer, index) in BestCustomerMonthlyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div class="info">
                                    <h4 class="text-black mb-3"><a class="text-black">#{{index + 1}} {{bestcustomer.organization}}</a></h4>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestcustomer.buy}}</strong> Products Bought</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="weeklycustomer">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-items3Content">
                            <div v-for="(bestcustomer, index) in BestCustomerWeeklyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div class="info">
                                    <h4 class="text-black mb-3"><a class="text-black">#{{index + 1}} {{bestcustomer.organization}}</a></h4>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestcustomer.buy}}</strong> Products Bought</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show active" id="allcustomer">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-itemsContent">
                            <div v-for="(bestcustomer, index) in BestCustomerAllList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div class="info">
                                    <h4 class="text-black mb-3"><a class="text-black">#{{index + 1}} {{bestcustomer.organization}}</a></h4>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestcustomer.buy}}</strong> Products Bought</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="annuallycustomer">
                        <div class="row height750 dz-scroll loadmore-content" id="favourite-items4Content">
                            <div v-for="(bestcustomer, index) in BestCustomerAnnuallyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                <div class="info">
                                    <h4 class="text-black mb-3"><a class="text-black">#{{index + 1}} {{bestcustomer.organization}}</a></h4>
                                    <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestcustomer.buy}}</strong> Products Bought</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header border-0 pb-0 d-sm-flex flex-wrap d-block">
                    <div class="mb-5">
                        <h4 class="card-title mb-1">Best Customer</h4>
                        <small class="mb-0">Customer with most product ordered</small>
                    </div>
                    <div class="card-action card-tabs mb-3">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#all" role="tab">
                                    All
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#monthly" role="tab">
                                    Monthly
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#weekly" role="tab">
                                    Weekly
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#annually" role="tab">
                                    Annually
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-body tab-content pt-3">
                    <div class="tab-pane fade show active" id="all">
                        <div class="height500 dz-scroll loadmore-content" id="sellingItemsContent">
                            <div v-for="(bestcustomer, index) in BestCustomerAllList" :key="index" class="media mb-5 items-list-2">
                                <div class="media-body col-6 px-0">
                                    <h4 class="mt-0 mb-1">#{{index + 1}} {{bestcustomer.organization}}</h4>
                                </div>
                                <div class="media-footer align-self-center ml-auto d-block align-items-center d-sm-flex">
                                    <h3 class="mb-0 font-w600 text-secondary">{{bestcustomer.buy}} Product Bought</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="monthly">
                        <div class="height500 dz-scroll loadmore-content" id="sellingItemsContent">
                            <div v-for="(bestcustomer, index) in BestCustomerMonthlyList" :key="index" class="media mb-5 items-list-2">
                                <div class="media-body col-6 px-0">
                                    <h4 class="mt-0 mb-1">#{{index + 1}} {{bestcustomer.organization}}</h4>
                                </div>
                                <div class="media-footer align-self-center ml-auto d-block align-items-center d-sm-flex">
                                    <h3 class="mb-0 font-w600 text-secondary">{{bestcustomer.buy}} Product Bought</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="weekly">
                        <div class="height500 dz-scroll loadmore-content" id="sellingItems2Content">
                            <div v-for="(bestcustomer, index) in BestCustomerWeeklyList" :key="index" class="media mb-5 items-list-2">
                                <div class="media-body col-6 px-0">
                                    <h4 class="mt-0 mb-1">#{{index + 1}} {{bestcustomer.organization}}</h4>
                                </div>
                                <div class="media-footer align-self-center ml-auto d-block align-items-center d-sm-flex">
                                    <h3 class="mb-0 font-w600 text-secondary">{{bestcustomer.buy}} Product Bought</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="annually">
                        <div class="height500 dz-scroll loadmore-content" id="sellingItems3Content">
                            <div v-for="(bestcustomer, index) in BestCustomerAnnuallyList" :key="index" class="media mb-5 items-list-2">
                                <div class="media-body col-6 px-0">
                                    <h4 class="mt-0 mb-1">#{{index + 1}} {{bestcustomer.organization}}</h4>
                                </div>
                                <div class="media-footer align-self-center ml-auto d-block align-items-center d-sm-flex">
                                    <h3 class="mb-0 font-w600 text-secondary">{{bestcustomer.buy}} Product Bought</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header bg-info">
                    <div>
                        <!-- <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> -->
                        <h4 class="card-title text-white">Sales Order Pending</h4>
                        <!-- </button> -->
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <input type="text" v-model="paramQuerySO.search" class="form-control w-50" placeholder="Search...">
                            <b-button title="Detail" @click="onSearchChangeSO" class="btn btn-secondary" size="sm"><i class="fa fa-search"></i> Search </b-button>
                            <b-button title="Clear" @click="clearInputSO" class="btn btn-warning" size="sm"><i class="fa fa-trash"></i> Clear </b-button>
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChangeSO" @on-per-page-change="onPerPageChangeSO" :totalRows="totalRecordsSO" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuerySO.page,
                perPage: 5,
              }" :rows="rowsSO" :columns="columnsSO" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Detail" :to="'detail?id='+ props.row.id" variant="outline-info" size="sm"><i class="fa fa-search"></i> Detail </b-button>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'delivery_status'">
                                <span class="btn btn-sm" :class="props.row.delivery_status == 'No Delivery' ? 'btn-danger' : 'btn-success' ">{{ props.row.delivery_status }}</span>
                            </div>
                            <div v-else-if="props.column.field == 'payment_status'">
                                <span class="btn btn-sm" :class="props.row.payment_status == 'UNPAID' ? 'btn-danger' : 'btn-success' ">{{ props.row.payment_status }}</span>
                            </div>
                            <div v-else-if="props.column.field == 'sales_force'">
                                <div v-if="props.row.sales_force !== null">
                                    <img :src="props.row.sales_force.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.sales_force.first_name }}</span>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div class="card">
                <div class="card-header bg-info">
                    <div>
                        <!-- <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"> -->
                        <h4 class="card-title text-white">Consignment Information</h4>
                        <!-- </button> -->
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <input type="text" v-model="paramQueryConsignment.search" class="form-control w-50" placeholder="Search...">
                            <b-button title="Detail" @click="onSearchChangeConsignment" class="btn btn-secondary" size="sm"><i class="fa fa-search"></i> Search </b-button>
                            <b-button title="Clear" @click="clearInputConsignment" class="btn btn-warning" size="sm"><i class="fa fa-trash"></i> Clear </b-button>
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChangeConsignment" @on-per-page-change="onPerPageChangeConsignment" :totalRows="totalRecordsConsignment" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQueryConsignment.page,
                perPage: 5,
              }" :rows="rowsConsignment" :columns="columnsConsignment" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Detail" :to="'detail-consignment?id='+ props.row.id" variant="outline-info" size="sm"><i class="fa fa-search"></i> Detail </b-button>
                                </b-button-group>
                            </div>
                            <div v-else-if="props.column.field == 'sales_force'">
                                <div v-if="props.row.sales_force !== null">
                                    <img :src="props.row.sales_force.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.sales_force.first_name }}</span>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
// import MenuApp from '../../components/MenuApp';
import axios from "axios";

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard Sales | %s',
    },
    // components: {
    //     MenuApp
    // },
    data() {
        return {
            denied: 0,
            totalSO: 0,
            totalDelivered: 0,
            approved: 0,
            target: 0,
            realization: 0,
            dropping: 0,
            totalSalesOrder: 0,
            paramQuerySO: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false
            },
            paramQueryConsignment: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false
            },
            totalRecordsSO: 0,
            totalRecordsConsignment: 0,
            rowsSO: [],
            rowsConsignment: [],
            BestProductAnnuallyList: [],
            BestProductWeeklyList: [],
            BestProductMonthlyList: [],
            BestProductAllList: [],
            BestCustomerAnnuallyList: [],
            BestCustomerWeeklyList: [],
            BestCustomerMonthlyList: [],
            BestCustomerAllList: [],
            columnsSO: [{
                    label: "No. SO",
                    field: "no_so",
                    hidden: false,
                },
                {
                    label: "Customer",
                    field: "customer",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Sales",
                    field: "sales_force",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Delivery Status",
                    field: "delivery_status",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Payment Status",
                    field: "payment_status",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Total",
                    field: "total",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "SO Date",
                    field: "date_so",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-left text-nowrap",
                    sortable: false,
                },
            ],
            columnsConsignment: [{
                    label: "No. SO",
                    field: "no_so",
                    hidden: false,
                },
                {
                    label: "Customer",
                    field: "customer_name",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Sales",
                    field: "sales_force",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Qty",
                    field: "qty",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Sold",
                    field: "sold",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Returned",
                    field: "returned",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Exchanged",
                    field: "exchanged",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Total Ammount",
                    field: "total_ammount",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Total Sold",
                    field: "sales_ammount",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Start",
                    field: "start_date",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "End",
                    field: "end_date",
                    thClass: 'text-center',
                    tdClass: "text-center text-nowrap",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
        };
    },
    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get('dental-erp-service/counting/so', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.denied = resp.data.data.total_so_denied;
                    this.totalSO = resp.data.data.total_so_created;
                    this.totalDelivered = resp.data.data.total_so_delivered;
                    this.approved = resp.data.data.total_so_approved;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getSO() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/so_list", this.paramQuerySO, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsSO = resp.data.pagination.total;
                    this.rowsSO = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getConsignment() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/cons_list", this.paramQueryConsignment, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsConsignment = resp.data.pagination.total;
                    this.rowsConsignment = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductAnnuallyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'annually'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductAnnuallyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductMonthlyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'monthly'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductMonthlyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductWeeklyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'weekly'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductWeeklyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductAllList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'all'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductAllList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestCustomerAnnuallyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_customer", {
                    filter: 'annually'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestCustomerAnnuallyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestCustomerWeeklyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_customer", {
                    filter: 'weekly'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestCustomerWeeklyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestCustomerMonthlyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_customer", {
                    filter: 'monthly'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestCustomerMonthlyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestCustomerAllList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_customer", {
                    filter: 'all'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestCustomerAllList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        updateParamsSO(newProps) {
            this.paramQuerySO = Object.assign({}, this.paramQuerySO, newProps);
        },

        updateParamsConsignment(newProps) {
            this.paramQueryConsignment = Object.assign({}, this.paramQueryConsignment, newProps);
        },

        onPageChangeSO(params) {
            this.updateParamsSO({
                page: params.currentPage
            });
            this.getSO();
        },

        onPageChangeConsignment(params) {
            this.updateParamsConsignment({
                page: params.currentPage
            });
            this.getConsignment();
        },

        onPerPageChangeSO(params) {
            this.updateParamsSO({
                limit: params.currentPerPage,
                page: 1
            });
            this.getSO();
        },

        onPerPageChangeConsignment(params) {
            this.updateParamsConsignment({
                limit: params.currentPerPage,
                page: 1
            });
            this.getConsignment();
        },

        // onSortChange(params) {
        //     this.updateParams({
        //         column: params[0].field,
        //         ascending: params[0].type == "desc" ? false : true,
        //     });
        //     this.getData();
        // },

        // onColumnFilter(params) {
        //     this.updateParams(params);
        //     this.getSO();
        //     this.getConsignment();
        // },

        onSearchChangeSO() {
            this.updateParamsSO({
                page: 1
            });
            this.getSO();
        },

        onSearchChangeConsignment() {
            this.updateParamsConsignment({
                page: 1
            });
            this.getConsignment();
        },

        clearInputConsignment() {
            this.paramQueryConsignment.search = ""
            this.getConsignment();
        },

        clearInputSO() {
            this.paramQuerySO.search = ""
            this.getSO();
        },
    },
    async mounted() {
        await this.$store.dispatch("setApp");
        await this.getData();
        await this.getSO();
        await this.getConsignment();
        await this.getBestProductAnnuallyList();
        await this.getBestProductWeeklyList();
        await this.getBestProductMonthlyList();
        await this.getBestProductAllList();
        await this.getBestCustomerAnnuallyList();
        await this.getBestCustomerWeeklyList();
        await this.getBestCustomerMonthlyList();
        await this.getBestCustomerAllList();
    },
};
</script>

<style>
.card-information {
    box-shadow: 3px 3px 3px rgb(205, 205, 212);
    background-color: #F7F7F7;
}
</style>
