<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <b-button title="Product Report" class="mr-2" variant="outline-success" to="product-report">
            <i class="fa fa-file-alt"></i>
            Product Report
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Data Product List</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Column</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" @input="handleSearch($event.target.value)" @keyup.enter="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                        enabled: true,
                        dropdownAllowAll: false,
                        nextLabel: 'Next',
                        setCurrentPage: paramQuery.page,
                        prevLabel: 'Previous',
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'price'">
                                <span v-if="props.row.price != null"> {{ props.row.price.toLocaleString('id-ID') }} </span>
                                <span v-else>-</span>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
// import {
//     BButton,
// } from "bootstrap-vue";

export default {
    name: "PriceList",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Price List | %s',
    },
    // components: {
    //     BButton
    // },
    data() {
        return {
            columns: [{
                    label: "Part Number",
                    field: "part_number",
                    // thClass: 'text-center',
                    // tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Name",
                    field: "name",
                    // thClass: 'text-center',
                    // tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Price",
                    field: "price",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Quantity",
                    field: "qty_avail",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Warehouse",
                    field: "warehouse_name",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Category",
                    field: "category_name",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Brand",
                    field: "brand_name",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Piece",
                    field: "piece",
                    hidden: false,
                },
                {
                    label: "Expired Date",
                    field: "expire_date",
                    // thClass: 'text-center',
                    // tdClass: "text-center text-red",
                    tdClass: "text-red",
                    hidden: false,
                },
            ],
            timeout: null,
            rows: [],
            propsEdit: {},
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                products: [],
                categories: [],
                brands: [],
                warehouses: [],
            },
        };
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i =>
                i.field != 'principle_name' &&
                i.field != 'category_name' &&
                i.field != 'current_stock' &&
                i.field != 'origin_stock' &&
                i.field != 'price.purchase_price' &&
                i.field != 'price.price.selling_price' &&
                i.field != 'price.max_discount' &&
                i.field != 'brand_name'
            )
        },
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        handleSearch: function () {
            clearTimeout(this.timeout);
            var self = this;
            this.timeout = setTimeout(function () {
                self.getData();
            }, 1000);
        },
        handleCancel() {
            this.propsEdit = {};
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        async getData() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post("dental-erp-service/product_list/sales", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async deleteData(row) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.delete(
                            "dental-erp-service/price_list/purchasing/destroy", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: row.price.id
                                }
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getData();
                        }

                    } catch (error) {
                        // this.$swal.fire("Error", error.response.data.message, "error");
                        // console.log(error);
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
        // async changeColumn(column) {
        //     for (var i in this.columns) {
        //         if (this.columns[i].label == column.label) {
        //             this.columns[i].hidden =
        //                 "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
        //             break;
        //         }
        //     }
        // },
    },
};
</script>
