<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>

        <b-button variant="outline-info" to="quotation">
            <b-icon icon="arrow-left" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Back to Quotation List
        </b-button>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Update Quotation</h4>
                    </div>
                    <!-- <div class="card-action card-tabs mt-3 mt-sm-0"></div> -->
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <form ref="formAdd" @submit.prevent="handleSubmit" class="mt-5">
                                <div class="form-row">
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Customer</label>
                                        <v-select label="organization" :filterable="false" :options="customers" v-model="formInput.name_customer" @input="customer => formInput.id_customer = customer.id" @search="onSearchCustomer">
                                            <template slot="no-options"> type to search customer... </template>
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.organization }}
                                                </div>
                                            </template>
                                            <template slot="selected-option" slot-scope="option">
                                                <div class="selected d-center">
                                                    {{ option.organization }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <label class="text-danger error">{{ validation.firstError('formInput.id_customer') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">No. Quotation</label>
                                        <input type="text" class="form-control" readonly placeholder="Enter the number quotation" required v-model="formInput.no_quotation" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.no_quotation') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Postage Stamp</label>
                                        <input type="text" class="form-control" placeholder="Enter the postage stamp" required v-model="formInput.postage_stamp" />
                                        <label class="text-danger error">{{ validation.firstError('formInput.postage_stamp') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Valid until (days)</label>
                                        <input type="number" min="1" class="form-control" v-model.number="formInput.valid_day">
                                        <label class="text-danger error">{{ validation.firstError('formInput.valid_day') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-3 col-xl-2">
                                        <label class="text text-info">Date</label>
                                        <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.quotation_date">
                                        </b-form-datepicker>
                                        <label class="text-danger error">{{ validation.firstError('formInput.quotation_date') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-2 col-xl-1">
                                        <label class="text text-info">&nbsp;</label>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="stockIndent" v-model="formInput.stock_indent" :true-value="1" :false-value="0">
                                            <label class="custom-control-label" for="stockIndent"> Stock Indent </label>
                                        </div>
                                        <label class="text-danger error">{{ validation.firstError('formInput.stock_indent') }}</label>
                                    </div>
                                    <div class="form-group col-md-4 col-lg-2 col-xl-1">
                                        <label class="text text-info">&nbsp;</label>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="binding" v-model="formInput.binding" :true-value="1" :false-value="0">
                                            <label class="custom-control-label" for="binding"> Binding </label>
                                        </div>
                                        <label class="text-danger error">{{ validation.firstError('formInput.stock_indent') }}</label>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="text text-info">Note</label>
                                        <textarea class="form-control" v-model="formInput.note" style="margin-top: 0px; margin-bottom: 0px; height: 129px;"></textarea>
                                        <label class="text-danger error">{{ validation.firstError('formInput.rate') }}</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr class="pb-2">
                    <form ref="formAdd" @submit.prevent="handleOk">
                        <div class="form-row">
                            <div class="form-group col-md-8 col-lg-9">
                                <label class="text text-info">Select Product</label>
                                <v-select label="name" :filterable="false" v-model="selected" :options="products" @search="onSearchProduct" @input="setSelectedProduct">
                                    <template slot="no-options"> type to search product... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <dl>
                                                <dt class="text text-sm text-muted">{{option.principle_name}}</dt>
                                                <dd>[{{option.part_number}}] {{option.name}}</dd>
                                            </dl>
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Qty</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" v-model.number="product.qty" placeholder="Enter the quantity" min="1">
                                    <div class="input-group-append">
                                        <b-button variant="outline-info" type="submit">
                                            <b-icon icon="plus-circle" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Add Product
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="pt-4 table-responsive" v-if="listProduct.length > 0">
                        <table class="table dark-table-bordered text-center">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Part Number</th>
                                    <th>Name</th>
                                    <th style="width: 6%">Qty</th>
                                    <th>Price</th>
                                    <th style="width: 10%">Disc (%)</th>
                                    <th>Total Disc</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val, index) in listProduct" :key="index">
                                    <td>{{ val.part_number }}</td>
                                    <td>{{ val.name }}</td>
                                    <td>
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.qty" @input="calculate(val)" @change="calculate(val)" />
                                    </td>
                                    <td>
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="val.price" readonly @input="calculate(val)" />
                                    </td>
                                    <td>
                                        <div class="input-group input-info">
                                            <input type="number" readonly min="0" class="form-control" @input="calculatePercent(val)" v-model.number="val.percent">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <currency-input type="text" readonly :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model.number="val.discount" @input="calculateDiscount(val)" />
                                    </td>
                                    <td class="text-right">{{ ((val.qty * val.price) - val.discount).toLocaleString('id-ID') }}</td>
                                    <td>
                                        <div class="text-center">
                                            <button class="btn btn-danger shadow btn-xs sharp" type="button" @click="deleteProduct(index)"><i class="fa fa-trash"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="tfoot-dark">
                                <tr>
                                    <td colspan="7" class="text-right"> Sub Total</td>
                                    <td class="text-right"> {{ calculateSubTotal.toLocaleString('id-ID') }} </td>
                                </tr>
                                <!-- <tr>
                                    <td colspan="7" class="text-right pt-4"> Shipping Charge</td>
                                    <td class="text-right">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.shipping_charge" @input="calculateShippingCharge" />
                                    </td>
                                </tr> -->
                                <tr>
                                    <td colspan="6" class="text-right">&nbsp;</td>
                                    <td class="text-right">
                                        <div class="input-group input-info">
                                            <div class="input-group-append">
                                                <span class="input-group-text">Discount</span>
                                            </div>
                                            <input type="number" min="0" class="form-control" v-model.number="formInput.percent" @input="calculateParentPercent">
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right">
                                        <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" v-model.number="formInput.discount" @input="calculateParentDiscount" />
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="7" class="text-right">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" v-model="formInput.include_tax" :true-value="1" :false-value="0" id="includeTax" @change="handleTax">
                                            <label class="custom-control-label" for="includeTax"> Include Tax </label>
                                        </div>
                                    </td>
                                    <td class="text-right"> {{ (formInput.tax_amount == 0) ? '' : formInput.tax_amount.toLocaleString('id-ID') }} </td>
                                </tr>
                                <tr>
                                    <td colspan="7" class="text-right">Amount Due</td>
                                    <td class="text-right"> {{ formInput.total_final.toLocaleString('id-ID') }} </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="card-footer border-0 pt-0" v-if="listProduct.length > 0">
                    <p class="card-text d-inline">
                        Total Amount Due : {{ calculateTotalAmount.toLocaleString('id-ID') }}
                    </p>
                    <button type="button" class="btn btn-outline-success float-right" @click="handleSubmit">
                        <i class="fa fa-save mr-1"></i> Create Quotation
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';
import {
    Validator
} from 'simple-vue-validator';
import "bootstrap-vue/dist/bootstrap-vue.css";
const moment = require("moment");

export default {
    name: 'UpdateQuotation',
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Update Quotation | %s',
    },
    data() {
        return {
            customers: [],
            products: [],
            listProduct: [],
            selected: [],
            product: {
                part_number: '',
                name: '',
                price: 0,
                discount: 0,
                percent: 0,
                principle_name: '',
                qty: 1,
                id_product: ''
            },
            formInput: {
                id_customer: '',
                name_customer: '',
                note: '',
                no_quotation: '',
                postage_stamp: '',
                binding: 0,
                valid_day: 30,
                stock_indent: 1,
                quotation_date: moment().format('YYYY-MM-DD'),
                id_sales: '',
                sub_total: 0,
                discount: 0,
                percent: 0,
                include_tax: 1,
                tax_amount: 0,
                total: 0,
                total_final: 0,
                total_shelter: 0,
                products: []
            }
        }
    },
    validators: {
        'formInput.id_customer': function (value) {
            return Validator.value(value).required('Customer is required');
        },
        'formInput.no_quotation': function (value) {
            return Validator.value(value).required('No Quotation Order is required');
        },
        'formInput.postage_stamp': function (value) {
            return Validator.value(value).required('Postage Stamp is required');
        },
        'formInput.quotation_date': function (value) {
            return Validator.value(value).required('Quotation Date is required');
        },
    },
    async created() {
        this.onLoad()
    },
    watch: {
        calculateSubTotal(subTotal) {
            this.formInput.sub_total = subTotal;
            // this.formInput.total = subTotal;

            this.calculateParentPercent()
            this.handleTax()
        },
        calculateTotalAmount(total) {
            this.formInput.total_final = total;
        }
    },
    computed: {
        calculateSubTotal() {
            return this.listProduct.reduce((accumulator, current) => accumulator + ((current.price * current.qty) - current.discount), 0);
        },
        calculateTotalAmount() {
            // let tax = (this.formInput.include_tax == 0) ? this.formInput.total_shelter * 0.1 : 0;
            let tax = (this.formInput.include_tax == 0) ? (this.formInput.total_shelter/1.1) * 0.1 : 0;
            return ((this.formInput.sub_total - this.formInput.discount) + tax);
        },
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('quotation');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/quotation/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    this.formInput.id_customer = resp.data.data.customer.id;
                    this.formInput.name_customer = resp.data.data.customer;
                    this.formInput.id = resp.data.data.id;
                    this.formInput.discount = resp.data.data.detail.discount;
                    this.formInput.include_tax = resp.data.data.detail.include_tax;
                    this.formInput.valid_day = resp.data.data.detail.valid_day;
                    this.formInput.quotation_date = resp.data.data.detail.quotation_date;
                    this.formInput.stock_indent = resp.data.data.detail.stock_indent;
                    this.formInput.binding = resp.data.data.detail.binding;
                    this.formInput.postage_stamp = resp.data.data.detail.postage_stamp;
                    this.formInput.note = resp.data.data.note;
                    this.requestNumberQuotation(resp.data.data.no_quotation)

                    // each every products
                    await resp.data.data.get_products.forEach(function (value, index) {

                        if (index == 0) {
                            arr.push({
                                part_number: value.product.part_number,
                                name: value.product.name,
                                is_bonus: 0,
                                price: value.price,
                                id_product: value.product.id,
                                discount: value.discount == null ? 0 : value.discount,
                                percent: 0,
                                approved: value.approved,
                                qty: 1
                            });
                        } else {

                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i].part_number == value.product.part_number) {
                                    found = true;
                                    arr[i].qty += 1;
                                    arr[i].discount += value.discount == null ? 0 : value.discount;

                                    break;
                                }
                            }

                            if (found === false) {
                                arr.push({
                                    part_number: value.product.part_number,
                                    name: value.product.name,
                                    is_bonus: 0,
                                    price: value.price,
                                    id_product: value.product.id,
                                    discount: value.discount == null ? 0 : value.discount,
                                    percent: 0,
                                    approved: value.approved,
                                    qty: 1
                                });
                            }
                        }
                    });
                    this.listProduct = arr;

                    return this.quotation = resp.data.data;
                }

                return this.$router.push('quotation');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Quotation", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Quotation ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async requestNumberQuotation(oldNumber) {
            try {
                const params = new URLSearchParams();
                params.append('old_number', oldNumber);
                params.append('type', 'SPH');
                params.append('back_date', 0);
                params.append('date', moment().format('YYYY-MM-DD'));

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_quotation = resp.data.data.number;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Number SO", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Number SO", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async calculatePercent(row) {
            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        async calculateDiscount(row) {
            row.percent = (parseFloat(row.discount || 0) / (parseFloat(row.price || 0) * parseFloat(row.qty || 0))) * 100;
        },
        async calculate(row) {
            if (row.qty == "" || row.price == "") return;

            row.discount = (parseFloat(row.percent || 0) / 100) * (parseFloat(row.price || 0) * parseFloat(row.qty || 0));
        },
        // async calculateShippingCharge() {
        //     this.formInput.total = this.formInput.sub_total + this.formInput.shipping_charge;
        //     this.calculateParentPercent();
        // },
        async calculateParentPercent() {
            this.formInput.total_shelter = this.formInput.sub_total;
            this.formInput.discount = (parseFloat(this.formInput.percent || 0) / 100) * parseFloat(this.formInput.total_shelter || 0);
            this.formInput.total_shelter = this.formInput.sub_total - this.formInput.discount;
            this.handleTax()
        },
        async calculateParentDiscount() {
            this.formInput.total_shelter = this.formInput.sub_total - this.formInput.discount;
            this.formInput.percent = (parseFloat(this.formInput.discount || 0) / parseFloat(this.formInput.sub_total || 0)) * 100;
            this.handleTax();
        },
        async handleTax() {
            this.formInput.total_shelter = this.formInput.sub_total - this.formInput.discount;
            this.formInput.tax_amount = (this.formInput.include_tax == 0) ? (this.formInput.total_shelter/1.1) * 0.1 : 0;
            // this.formInput.tax_amount = (this.formInput.include_tax == 0) ? this.formInput.total_shelter * 0.1 : 0;
        },
        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/customer", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.customers = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Customer", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Customer ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/product", {
                        limit: 20,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.products = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Products", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Products ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        setSelectedProduct(product) {
            if (product == null) {
                this.product = {
                    id_product: '',
                    name: '',
                    qty: this.product.qty
                }
                return;
            }

            this.product = {
                part_number: product.part_number,
                principle_name: product.principle_name,
                id_product: product.id,
                name: product.name,
                qty: this.product.qty,
                price: 0,
                discount: 0,
                percent: 0,
                is_bonus: 0,
            }
        },
        async deleteProduct(index) {
            this.listProduct.splice(index, 1);
        },
        async handleOk() {
            if (this.product.id_product == "" || this.product.qty == "") {
                return false;
            }

            this.selected = [];

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    'dental-erp-service/price_list/purchasing/find', {
                        id_product: this.product.id_product
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.product.price = resp.data.data.price == null ? 0 : resp.data.data.price.selling_price
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Price", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Product Price", error.message, "error");
            } finally {

                this.listProduct.push(this.product);
                this.product = {
                    part_number: '',
                    name: '',
                    price: 0,
                    discount: 0,
                    percent: 0,
                    principle_name: '',
                    qty: 1,
                    id_product: '',
                    is_bonus: 0
                };
                this.$store.dispatch("setLoading", false);
            }
        },
        async handleSubmit() {

            let validation = await this.$validate();
            if (!validation) {
                return false;
            }

            if (this.listProduct.length == 0) {
                this.$swal.fire("Error", 'The list product can not be empty', "error");
                return;
            }

            this.formInput.total = this.formInput.total_final
            
            this.formInput.products = await this.listProduct.map(v => {
                return {
                    id: v.id_product,
                    qty: v.qty,
                    price: v.price,
                    discount: 0,
                }
            });

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        await this.$store.dispatch("setLoading", true);
                        let resp = await axios.post(
                            'dental-erp-service/quotation/sales/store',
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.$router.push('quotation')
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }

                }
            });

        },
        clearForm() {
            var self = this; //you need this because *this* will refer to Object.keys below`
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.formInput).forEach(function (key) {
                self.formInput[key] = '';
            });
        }
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
