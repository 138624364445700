<template>
<div>
    <vue-html2pdf :show-layout="false" :float-layout="false" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="2000" :filename="'Quotation ' + this.shelter.no_quotation === undefined ? '-' : this.shelter.no_quotation" :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" pdf-content-width="97%" ref="html2Pdf" v-if="Object.keys(shelter).length > 0">
        <section slot="pdf-content">
            <div class="container-fluid invoice-container">
                <header>
                    <div class="row">
                        <div class="col-sm-2 offset-md-2">
                            <div class="row">
                                <div class="col-md-12">
                                    <img id="logo" width="90px" height="90px" src="/assets/logo/gratia logo.png" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" style="font-size: 10px;">
                            <dir class="row">
                                <div class="col-md-12 mt-2">
                                    <h3 class="mb-0">PT. GRATIA JAYA MULYA</h3>
                                    <p class="mb-0">Hospital & Medical Equipment Supplier</p>
                                </div>
                            </dir>
                        </div>
                    </div>
                    <hr>
                </header>
                <main>
                    <div class="row">
                        <div class="col-sm-6 order-sm-1">
                            <!-- <address>
                                {{ shelter.payment_account.npwp }}<br />
                                {{ shelter.payment_account.account_number }}<br />
                                {{ shelter.payment_account.branch }}<br />
                            </address> -->
                        </div>
                        <div class="col-sm-6 order-sm-0">
                            <table style="border: none !important;">
                                <tr style="border: none !important;">
                                    <td style="border: none !important;" width="30%">Nomor</td>
                                    <td style="border: none !important;" width="5%">:</td>
                                    <td style="border: none !important;" width="75%">{{ shelter.no_quotation }}</td>
                                </tr>
                                <tr style="border: none !important;">
                                    <td style="border: none !important;" width="30%">Lampiran</td>
                                    <td style="border: none !important;" width="5%">:</td>
                                    <td style="border: none !important;" width="75%">&nbsp;</td>
                                </tr>
                                <tr style="border: none !important;">
                                    <td style="border: none !important;" width="30%">Perihal</td>
                                    <td style="border: none !important;" width="5%">:</td>
                                    <td style="border: none !important;" width="75%">Surat Penawaran Harga</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-sm-12">
                            <!-- Dengan Hormat,  -->
                            Kepada Yth.
                            <p>{{shelter.customer.organization}}</p>
                            <p style="text-indent: 35px">Bersama ini kami PT Gratia Jaya Mulya sebagai sole agent, bermaksud untuk memberikan informasi daftar harga untuk alat-alat kesehatan untuk memenuhi kebutuhan di institusi TNI sebagai berikut :</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table">
                                <tr>
                                    <td>No</td>
                                    <td>Nama Barang</td>
                                    <td>Jumlah</td>
                                    <td>Merk / Negara Asal</td>
                                    <td>Harga Satuan</td>
                                    <td>Jumlah Harga</td>
                                </tr>
                                <tr v-for="(val, id) in filterProduct" :key="id">
                                    <td>{{ id+1 }}</td>
                                    <td>{{ val.product_name }}</td>
                                    <td>{{ val.qty }}</td>
                                    <td>-</td>
                                    <td>{{ val.price.toLocaleString('ID-id') }}</td>
                                    <td>{{ (val.price * val.qty).toLocaleString('ID-id') }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            Adapun kondisi harga tersebut :
                            <ul class="ml-3">
                                <li v-if="shelter.detail.include_tax == 1">1. Sudah termasuk PPN 10%.</li>
                                <li v-else>1. Belum termasuk PPN 10%.</li>
                                <li>2. Frangko {{ shelter.detail.postage_stamp}}.</li>
                                <li>3. Tidak mengikat dan sewaktu-waktu dapat berubah tanpa pemberitahuan terlebih dahulu.</li>
                                <li>4. Berlaku {{ shelter.detail.valid_day}} hari sejak ditanda-tangani.</li>
                                <li v-if="shelter.detail.stock_indent == 1">5. Barang Indent.</li>
                                <li v-else>5. Barang Tidak Indent.</li>
                            </ul>
                        </div>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-sm-12">
                            Demikian surat penawaran ini kami buat, atas kerjasamanya saya ucapkan Terima Kasih.
                        </div>
                    </div>
                </main>
                <br>
                <br>
                <footer class="text-left">
                    Jakarta, {{ new Date() | moment('DD MMMM YYYY')}} <br>
                    <strong>PT. Gratia Jaya Mulya</strong><br>
                    <br><br><br><br><br>
                    <u>{{ signatureAs }}</u><br>
                    {{ signatureTitle }}
                </footer>
            </div>
        </section>
    </vue-html2pdf>
</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
// import axios from "axios";

export default {
    name: 'PrintQuotation',
    props: {
        dataQuotation: {
            type: Object,
            required: true
        },
        signatureAs: String,
        signatureTitle: String,
    },
    watch: {
        dataQuotation: {
            immediate: true,
            handler() {
                this.shelter = Object.assign({}, this.dataQuotation);
            }
        },
    },
    data() {
        return {
            shelter: {},
        };
    },
    components: {
        VueHtml2pdf
    },
    computed: {
        filterProduct() {
            let arr = [];

            if (this.shelter.get_products === undefined) {
                return arr;
            }

            this.shelter.get_products.forEach(function (value, index) {

                if (index == 0) {
                    arr.push({
                        part_number: value.product.part_number,
                        product_name: value.product.name,
                        price: value.price,
                        discount: value.discount == null ? 0 : value.discount,
                        approved: value.approved,
                        qty: 1,
                    });
                } else {

                    let found = false;

                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].part_number == value.product.part_number) {
                            found = true;
                            arr[i].qty += 1;
                            arr[i].discount += value.discount == null ? 0 : value.discount;
                            break;
                        }
                    }

                    if (found === false) {
                        arr.push({
                            part_number: value.product.part_number,
                            product_name: value.product.name,
                            price: value.price,
                            discount: value.discount == null ? 0 : value.discount,
                            approved: value.approved,
                            qty: 1,
                        });
                    }
                }
            });
            return arr;
            // return arr.sort((a, b) => a.is_bonus - b.is_bonus);
        }
    },
    methods: {
        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1
            };
            var str = '';

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        }
    }
}
</script>

<style scoped>
.container-fluid {
    background: #e7e9ed;
    color: #535b61;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    /* line-height: 22px; */
}

form {
    padding: 0;
    margin: 0;
    display: inline;
}

img {
    vertical-align: inherit;
}

a,
a:focus {
    color: #0071cc;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:hover,
a:active {
    color: #0c2f55;
    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:focus,
a:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
button:active {
    outline: none;
}

p {
    line-height: 1.9;
}

blockquote {
    border-left: 5px solid #eee;
    padding: 10px 20px;
}

iframe {
    border: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #0c2f54;
    font-family: "Poppins", sans-serif;
}

.table {
    color: #535b61;
    line-height: 2px;
}

.table-hover tbody tr:hover {
    background-color: #f6f7f8;
}

/* Border Radius */
.rounded-top-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.rounded-left-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rounded-right-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

/* Text Size */
.text-0 {
    font-size: 11px !important;
    font-size: 0.6875rem !important;
}

.text-1 {
    font-size: 12px !important;
    font-size: 0.75rem !important;
}

.text-2 {
    font-size: 14px !important;
    font-size: 0.875rem !important;
}

.text-3 {
    font-size: 16px !important;
    font-size: 1rem !important;
}

.text-4 {
    font-size: 15px !important;
    font-size: 1.125rem !important;
}

.text-5 {
    font-size: 21px !important;
    font-size: 1.3125rem !important;
}

.text-6 {
    font-size: 24px !important;
    font-size: 1.50rem !important;
}

.text-7 {
    font-size: 28px !important;
    font-size: 1.75rem !important;
}

.text-8 {
    font-size: 32px !important;
    font-size: 2rem !important;
}

.text-9 {
    font-size: 36px !important;
    font-size: 2.25rem !important;
}

.text-10 {
    font-size: 40px !important;
    font-size: 2.50rem !important;
}

.text-11 {
    font-size: 44px !important;
    font-size: 2.75rem !important;
}

.text-12 {
    font-size: 48px !important;
    font-size: 3rem !important;
}

.text-13 {
    font-size: 52px !important;
    font-size: 3.25rem !important;
}

.text-14 {
    font-size: 56px !important;
    font-size: 3.50rem !important;
}

.text-15 {
    font-size: 60px !important;
    font-size: 3.75rem !important;
}

.text-16 {
    font-size: 64px !important;
    font-size: 4rem !important;
}

.text-17 {
    font-size: 72px !important;
    font-size: 4.5rem !important;
}

.text-18 {
    font-size: 80px !important;
    font-size: 5rem !important;
}

.text-19 {
    font-size: 84px !important;
    font-size: 5.25rem !important;
}

.text-20 {
    font-size: 92px !important;
    font-size: 5.75rem !important;
}

/* Line height */
.line-height-07 {
    line-height: 0.7 !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-2 {
    line-height: 1.2 !important;
}

.line-height-3 {
    line-height: 1.4 !important;
}

.line-height-4 {
    line-height: 1.6 !important;
}

.line-height-5 {
    line-height: 1.8 !important;
}

/* Font Weight */
.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/* Opacity */
.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-10 {
    opacity: 1;
}

/* Background light */
.bg-light {
    background-color: #FFF !important;
}

.bg-light-1 {
    background-color: #f9f9fb !important;
}

.bg-light-2 {
    background-color: #f8f8fa !important;
}

.bg-light-3 {
    background-color: #f5f5f5 !important;
}

.bg-light-4 {
    background-color: #eff0f2 !important;
}

.bg-light-5 {
    background-color: #ececec !important;
}

@media print {

    .table td,
    .table th {
        background-color: transparent !important;
    }

    .table td.bg-light,
    .table th.bg-light {
        background-color: #FFF !important;
    }

    .table td.bg-light-1,
    .table th.bg-light-1 {
        background-color: #f9f9fb !important;
    }

    .table td.bg-light-2,
    .table th.bg-light-2 {
        background-color: #f8f8fa !important;
    }

    .table td.bg-light-3,
    .table th.bg-light-3 {
        background-color: #f5f5f5 !important;
    }

    .table td.bg-light-4,
    .table th.bg-light-4 {
        background-color: #eff0f2 !important;
    }

    .table td.bg-light-5,
    .table th.bg-light-5 {
        background-color: #ececec !important;
    }
}

/* =================================== */
/*  Layouts
/* =================================== */
.invoice-container {
    margin: 0 auto;
    /* padding-top: 70px; */
    padding: 40px 30px 30px 40px;
    max-width: 850px;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    /* -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px; */
    /* border-radius: 6px; */
}

/* =================================== */
/*  Extras
/* =================================== */
.bg-primary,
.badge-primary {
    background-color: #0071cc !important;
}

.bg-secondary {
    background-color: #0c2f55 !important;
}

.text-secondary {
    color: #0c2f55 !important;
}

.text-primary {
    color: #0071cc !important;
}

.btn-link {
    color: #0071cc;
}

.btn-link:hover {
    color: #0e7fd9 !important;
}

.border-primary {
    border-color: #0071cc !important;
}

.border-secondary {
    border-color: #0c2f55 !important;
}

.btn-primary {
    background-color: #0071cc;
    border-color: #0071cc;
}

.btn-primary:hover {
    background-color: #0e7fd9;
    border-color: #0e7fd9;
}

.btn-secondary {
    background-color: #0c2f55;
    border-color: #0c2f55;
}

.btn-outline-primary {
    color: #0071cc;
    border-color: #0071cc;
}

.btn-outline-primary:hover {
    background-color: #0071cc;
    border-color: #0071cc;
    color: #fff;
}

.btn-outline-secondary {
    color: #0c2f55;
    border-color: #0c2f55;
}

.btn-outline-secondary:hover {
    background-color: #0c2f55;
    border-color: #0c2f55;
    color: #fff;
}

.progress-bar,
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #0071cc;
}

.page-item.active .page-link,
.custom-radio .custom-control-input:checked~.custom-control-label:before,
.custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label:before,
.custom-control-input:checked~.custom-control-label:before {
    background-color: #0071cc;
    border-color: #0071cc;
}

.list-group-item.active {
    background-color: #0071cc;
    border-color: #0071cc;
}

.page-link {
    color: #0071cc;
}

.page-link:hover {
    color: #0e7fd9;
}

/* Pagination */
.page-link {
    border-color: #f4f4f4;
    border-radius: 0.25rem;
    margin: 0 0.3rem;
}

.page-item.disabled .page-link {
    border-color: #f4f4f4;
}

table,
th,
td {
    border: 1px solid black;
}

table {
    border-bottom: 0;
    border-left: 0;
}

td,
th {
    border-top: 0;
    border-right: 0;
}

.table tbody tr td {
    border-color: black !important
}

.table thead tr th {
    border-color: black !important
}
</style>
